import {update_project_list_view_columns, update_project_layout} from 'mobile/utils/project_board.js'


function chooseDateFormat(clock24, date, time)  {
  var format;
  if (time) {
    if (clock24) {
      return format = "mmm d, HH:MM";
    } else {
      if (new Date(date).getMinutes() === 0) {
        return format = "mmm d, htt";
      } else {
        return format = "mmm d, h:MMtt";
      }
    }
  } else {
    return format = "UTC:mmm d";
  }
};

$.fn.equalHeights = function(px) {
  var currentTallest, that;
  if ($(".project-list-view").length > 0 || $(".custom-background").length > 0) {
    return false;
  }
  currentTallest = 100;
  that = $(this);
  $(this).each(function() {
    if ($(this).hasClass('collapsed-stage') || $(this).parent().hasClass('collapsed-stage')) {
      return true;
    }
    if ($(this).height() > currentTallest) {
      return currentTallest = $(this).height();
    }
  });
  $(this).css({
    "min-height": currentTallest
  });
  return this;
};


$.fn.equalWidths = function(px) {
  var currentWidth;
  if ($(".sortable_stage").length === 0 || $(".project-list-view").length > 0) {
    return false;
  }
  currentWidth = 0;
  $(this).each(function() {
    currentWidth = parseInt(($(".card-sort").outerWidth()) / $(".sortable_stage:first >.stage_class:not(.collapsed-stage)").length);
    $(this).children().css({
      "width": currentWidth - 5
    });
    $(this).children().css({
      "max-width": "320px"
    });
    return $(this).children().css({
      "min-width": "250px"
    });
  });
  if (currentWidth < 250) {
    currentWidth = 250;
  }
  Breeze.scroller = 0;
  if ($(".card-sort")[0]) {
    Breeze.scroller_max = $(".card-sort")[0].scrollWidth - $(".card-sort")[0].clientWidth;
  }
  if ($(".card-sort").width() < (currentWidth * $(".sortable_stage:first >.stage_class").length)) {
    $(".carousel-control.next").show();
  } else {
    if (!($('.task-board').length > 0 && $(".carousel-control.next").attr('href').length > 0)) {
      $(".carousel-control.next").hide();
    }
  }
  return this;
};

function stage_equal_heights (swimlane) {
  swimlane.find('.stage_class').equalHeights();
  swimlane.css('min-height', $(this).find('.stage_class:visible').height() + 20);
  swimlane.find('.stage_class .sortable').equalHeights();
};

$.fn.show_overdue_tasks = function() {
  var day, today;
  today = new Date().setHours(0, 0, 0, 0);
  day = 86400000;
  $(this).find(".time-bubble").each(function() {
    var planned;
    planned = parseInt($(this).attr('data-planned'));
    if (planned > 0 && (parseInt($(this).attr('data-tracked')) > planned)) {
      return $(this).addClass("over");
    }
  });
  this.filter(".card").each(function() {
    var d, duedate;
    if ($(this).attr('data-duedate')) {
      if ($(this).attr('data-duedate-time') === "true") {
        duedate = new Date($(this).attr('data-duedate')).setHours(0, 0, 0, 0);
      } else {
        d = new Date($(this).attr('data-duedate'));
        duedate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0).setHours(0, 0, 0, 0);
      }
      if (duedate === today) {
        if ($(this).attr('data-duedate-time')) {
          if (new Date().getTime() > new Date($(this).attr('data-duedate')).getTime()) {
            $(this).find('.calendar-bubble').addClass('overdue-task red');
          }
        } else {
          $(this).find('.calendar-bubble').addClass('overdue-task red');
        }
      } else if (duedate === (today - day)) {
        $(this).find('.calendar-bubble').addClass('overdue-task red');
      } else if (duedate === (today + day)) {
        $(this).find('.calendar-bubble').addClass('overdue-task yellow');
      } else if (duedate < today) {
        $(this).find('.calendar-bubble').addClass('overdue-task gray');
      }
      if ($(this).find('.text-overlay:contains("Done")').length > 0 || $(this).find('.text-overlay:contains("done")').length > 0) {
        $(this).find('.calendar-bubble').addClass('done');
      }
    }
  });
};

$.user_name = function(user) {
  if (!user.firstname) {
    return user.email;
  } else {
    return user.firstname;
  }
};

$.user_name_email = function(user) {
  if (!user.firstname) {
    return user.email;
  } else {
    return user.firstname + " " + user.email + "";
  }
};

$.stages_resize = function() {
  var h;
  h = $(window).height() - $("#main_header").height() - $(".workspace .row:first-child").height() - 140;
  $(".card-sort").css('min-height', h + 'px');
  return setTimeout((function() {
    return Breeze.sview_height = $(".card-sort").height();
  }), 100);
};

function developement() {
  return window.location.hostname === "breeze.localhost" || window.location.hostname === "app.breeze.test" || window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"  || window.location.hostname === "m.breeze.test" ;
};

function production () {
  return !developement();
};

function init_board_list_view () {
  if ($('.project-board.project-list-view').length > 0) {
    $('.project-board.project-list-view .card').each(function() {
      $(this).find(".drop-shadow .card_avatars").insertBefore($(this).find(".drop-shadow .badges"));
      $(this).find(".text-overlay-container").appendTo($(this).find(".drop-shadow .badges"));
    });
		
    update_project_list_view_columns();
    update_project_layout();
  }
};


function load_card_globals(card, project, callback) {
  var url;
  Breeze.projects = Breeze.projects || {};
  if (developement()) {
    url = ("https://app.breeze.test:3006/projects/" + project + "/global_data.json?auth_token=") + Breeze.current_user.token;
  } else {
    url = ("https://breeze-node-n.herokuapp.com/projects/" + project + "/global_data.json?auth_token=") + Breeze.current_user.token;
  }
  if (Breeze.projects[project] == null) {
    return $.ajax({
      type: "GET",
      queue: true,
      url: url,
      success: function(data) {
        Breeze.projects[project] = {};
        Breeze.projects[project].project_stages = Breeze.project_stages = data.project_stages;
        Breeze.projects[project].project_users = Breeze.project_users = data.project_users;
        Breeze.projects[project].project_statuses = Breeze.project_statuses = data.project_statuses;
				
				Breeze.projects[project].project_file_storage = Breeze.project_file_storage = data.project_file_storage
				Breeze.projects[project].project_file_storage_google_path = Breeze.project_file_storage_google_path = data.project_file_storage_google_path
				Breeze.projects[project].project_file_storage_google_path_id = Breeze.project_file_storage_google_path_id = data.project_file_storage_google_path_id
			
				Breeze.projects[project].autoduedate_status_id = Breeze.autoduedate_status_id = data.autoduedate_status_id
				
				
			
        return callback();
      }
    });
  } else {
    Breeze.project_stages = Breeze.projects[project].project_stages;
    Breeze.project_users = Breeze.projects[project].project_users;
    Breeze.team_users = Breeze.projects[project].team_users;
    Breeze.project_statuses = Breeze.projects[project].project_statuses;
		
		Breeze.project_file_storage = Breeze.projects[project].project_file_storage
		Breeze.project_file_storage_google_path = Breeze.projects[project].project_file_storage_google_path
		Breeze.project_file_storage_google_path_id = Breeze.projects[project].project_file_storage_google_path_id
		
		Breeze.autoduedate_status_id = Breeze.projects[project].autoduedate_status_id
		
    return callback();
  }
};


export {chooseDateFormat,developement,production,stage_equal_heights,load_card_globals,init_board_list_view};