import {developement} from 'mobile/utils.js';
import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';

var init_page;

$(document).ready(function() {
  $('.project-board').on("click", ".project-board-index", function(e) {
    var dpath;
    if ($('.project-analytics-view').length > 0) {
      dpath = "/dashboard";
    } else if ($('.project-activity-view').length > 0) {
      dpath = "/activity";
    } else {
      dpath = "";
    }
		
		$('.project-board-loader').removeClass("hide")
    $('#project-messages-modal').removeClass("active").css("display","")
		
    if (!window.history_navigate_back) {
      window.history.pushState({
        "static": true,
        messages_close: true,
        url: "/projects/" + Breeze.project + dpath
      }, $("title").text(), "/projects/" + Breeze.project + dpath);
      // Breeze.update_nav_url();
    }
    if (!Breeze.current_user.observer) {
      // key.setScope("board");
    }
    $('#project-messages-modal .modal-body').html('');
    froala_setup.heightMin = 120;
    froala_setup.toolbarBottom = false;
    froala_setup.toolbarSticky = true;
    // message_anchor = null;
    $('[data-action="project-messages"] .number-circle').text("");
    // if ($('.latest-notifications').data('notifications-count') <= 0) {
    //   return updateFavicon(false);
    // }
		$('.project_cog').trigger("click")
		
		return false
  });
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      return init_page();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    init_page();
  }
  return window.onfocus = function() {
    if ($('#project-messages-modal.in').length > 0) {
      // if ($('.latest-notifications').data('notifications-count') <= 0) {
      //   return updateFavicon(false);
      // }
    }
  };
});

init_page = function() {
  var loc;
  loc = window.location.pathname.match(/messages/);
  if (loc && loc.length > 0) {
    Breeze.message_anchor = window.location.pathname.split("/")[4];
    setTimeout((function() {
      $(".project-board-messages").trigger("click");
    }), 150);
  }
  $('.project-board').on("click", ".project-board-messages", function(e) {
    var url, url_hist, h;
    $('.project-analytics-statuses .status-editor .submit .cancel').trigger("click");
    if (developement()) {
      url = "";
    } else {
      url = "https://breeze-node-n4.herokuapp.com";
    }
		

		h = ($(window).height() - $(".head-row").height() - $(".navbar").height() - 80)
		$(".chat-right-content-room").css('height',h+'px')
		$('.project_cog').trigger("click")
    $('#project-messages-modal').html('');
    $('#project-messages-modal').append(Breeze.global_loader);
    $("#project-messages-modal").addClass("active").css("display","block");
		$('.project-board-loader').addClass("hide")
    // key.setScope("task");
    url_hist = "/projects/" + Breeze.project + "/messages";
    if (!window.history_navigate_back) {
      window.history.pushState({
        "static": true,
        messages_modal: true,
        url: url_hist
      }, $("title").html(), url_hist);
      // Breeze.update_nav_url();
    }
    $.ajax({
      type: 'GET',
      url: url + "/projects/" + Breeze.project + "/messages_all",
      data: {
        auth_token: Breeze.current_user.token,
        partial: true,
        message_id: Breeze.message_anchor
      },
      success: function(data) {
        var j, len, ref, results, token;
        $('#project-messages-modal .m-loader').remove();
        $('#project-messages-modal ').html('<div class="rooms"><a href="#" class="close" data-dismiss="modal">×</a>' + data + '</div>');
        $('#project-messages-modal .room-message-empty').html('<span>This is a place to discuss and leave message to everybody in the project</span>');
        
				h = ($(window).height() - $(".head-row").height() - $(".navbar").height() - 80)
				$(".chat-right-content-room").css('height',h+'px')
				
				loc = window.location.pathname.match(/\/messages\/(\d.*)/);
        Breeze.mention_users = $.map(Breeze.project_users, function(value, i) {
          return {
            id: value.id,
            name: $.user_name(value).replace(/\s+/g, ''),
            display: $.user_name_email(value),
            email: value.email,
            fname: value.firstname ? value.firstname : ""
          };
        });
        $('[data-action="project-messages"] .number-circle').text("");
				
				
        $(document).trigger("breeze:page", "rooms");
        if (Breeze.is_socket_connected() && Breeze.current_user.room_tokens) {
          ref = Breeze.current_user.room_tokens;
          results = [];
          for (j = 0, len = ref.length; j < len; j++) {
            token = ref[j];
            if (token) {
              results.push(Breeze.socket.emit("subscribe", token));
            } else {
              results.push(void 0);
            }
          }
          return results;
        }
      }
    });
    return false;
  });
};

// ---
// generated by coffee-script 1.9.2