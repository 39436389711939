import {update_project_list_view_columns} from 'mobile/utils/project_board.js'
import {developement} from 'mobile/utils.js';

var init_reactions, init_reactions_tooltip,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_reactions();
  }
  $(document).on("breeze:page", function(e, page) {
    if (page === "comments") {
      return init_reactions();
    }
  });
  return $(document).on("breeze:page", function(e, page) {
    if (page === "card_modal") {
      return init_reactions_tooltip();
    }
  });
});

init_reactions_tooltip = function() {
  $('#card_modal .user-reaction').tooltip({
    placement: "bottom",
		animation: false,
		sanitize: false,
		html: true,
    template: '<div class="tooltip reaction-user-tooltip"><div></div><div class="tooltip-inner"></div></div>',
    title: function() {
      var c, ids, names, ref, title, users;
      ids = (ref = $(this).attr('data-user-ids')) != null ? ref.split(",").map(Number) : void 0;
      users = _.filter(Breeze.all_team_users, function(u) {
        var ref1;
        return ref1 = u.id, indexOf.call(ids, ref1) >= 0;
      });
      names = _.pluck(users, "name");
      if (names.length > 16) {
        c = names.length - 16;
        names = names.slice(0, 15).join("<br>") + "<br> and " + c + " more ...";
      } else {
        names = names.join("<br>");
      }
      title = "ok";
      if ($(this).attr('data-emoji-id') === "1") {
        title = "Thumbs up";
      } else if ($(this).attr('data-emoji-id') === "2") {
        title = "Thumbs down";
      } else if ($(this).attr('data-emoji-id') === "3") {
        title = "Clapping";
      } else if ($(this).attr('data-emoji-id') === "4") {
        title = "Handshake";
      } else if ($(this).attr('data-emoji-id') === "5") {
        title = "Heart";
      } else if ($(this).attr('data-emoji-id') === "6") {
        title = "Grinning";
      } else if ($(this).attr('data-emoji-id') === "7") {
        title = "Tears of joy";
      } else if ($(this).attr('data-emoji-id') === "8") {
        title = "Angry";
      } else if ($(this).attr('data-emoji-id') === "9") {
        title = "Astonished";
      } else if ($(this).attr('data-emoji-id') === "10") {
        title = "Worried";
      } else if ($(this).attr('data-emoji-id') === "11") {
        title = "Done";
      }
			
      return "<div><b>" + title + "</b></div>" + names;
    }
  });
};

init_reactions = function() {
  $('#card_modal, #project-messages-modal, .workspace.rooms').on("click", ".user-reaction", function() {
    var card_reactions, comment_id, count, new_count, r, reaction_id, reactions, ref, ref1, rid, socket_params, user_ids;
    r = $(this);
    rid = $(this).attr('data-emoji-id');
    comment_id = $(this).closest('.comment-box').attr('data-comment-id');
    count = r.find('.count');
    reaction_id = r.attr('data-reaction-id');
    reactions = $(this).parent();
    user_ids = (ref = r.attr('data-user-ids')) != null ? ref.split(",").map(Number) : void 0;
    if (ref1 = Breeze.current_user.id, indexOf.call(user_ids, ref1) >= 0) {
      new_count = Number(count.text()) - 1;
      user_ids = _.without(user_ids, Breeze.current_user.id);
      if (new_count <= 0) {
        r.remove();
      } else {
        count.text(new_count);
        r.attr('data-user-ids', user_ids);
      }
    } else {
      user_ids.push(Breeze.current_user.id);
      count.text(Number(count.text()) + 1);
      r.attr('data-user-ids', user_ids);
    }
    card_reactions = "";
    $('#card_modal .card-owner-container .reactions .user-reaction').each(function() {
      return card_reactions += $(this)[0].outerHTML;
    });
    $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .reactions').html(card_reactions);
    socket_params = {
      project: Breeze.project_token,
      method: "reactions-create",
      comment_id: comment_id,
      card_id: Breeze.Card.id,
      card_reaction_html: card_reactions,
      comment_reaction_html: reactions.html()
    };
    $.ajax({
      type: "PUT",
      url: '/reactions/' + reaction_id,
      queue: true,
      data: {
        reaction: {
          comment_id: comment_id,
          card_id: Breeze.Card.id,
          emoji_id: r.attr("data-emoji-id")
        }
      },
      success: function() {
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    $('.tooltip').remove();
		update_project_list_view_columns("reactions")
    return false;
  });
	

	$('#card_modal, #project-messages-modal, .workspace.rooms').on("click", ".add-reaction", function() {
	  var card_popover, comment_id, container, el, element_id, rooms_message_id;
	  el = $(this);
	  comment_id = $(this).closest('.comment-box').attr('data-comment-id');
	  rooms_message_id = $(this).closest('.room-message-box').attr('data-message-id');
	  element_id = comment_id || rooms_message_id;
		
		
	  if ($('#reactions-menu[data-id="' + element_id + '"]').length > 0) {
	    el.popover("hide");
      el.removeData("bs.popover");
	    $('#reactions-menu').parent().remove();
	  } else {
      el.removeData("bs.popover");
      $('#reactions-menu').parent().remove();
      card_popover = $('<div> <span class="reaction" title="Thumbs up" data-emoji-id="1"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f44d.svg"></span> <span class="reaction" title="Thumbs down" data-emoji-id="2"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f44e.svg"></span> <span class="reaction" title="Clapping" data-emoji-id="3"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f44f.svg"></span> <span class="reaction" title="Handshake" data-emoji-id="4"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f91d.svg"></span> <span class="reaction" title="Heart" data-emoji-id="5"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/2764.svg"></span> <span class="reaction" title="Grinning" data-emoji-id="6"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f600.svg"></span> <span class="reaction" title="Tears of joy" data-emoji-id="7"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f602.svg"></span> <span class="reaction" title="Angry" data-emoji-id="8"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f621.svg"></span> <span class="reaction" title="Astonished" data-emoji-id="9"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f632.svg"></span> <span class="reaction" title="Worried" data-emoji-id="10"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/1f61f.svg"></span> <span class="reaction" title="Done" data-emoji-id="11"><img src="https://d1wdkw032nxkks.cloudfront.net/emojione-2.2.7/svg/2705.svg"></span> </div>');
      el.popover({
        trigger: "manual",
        container: el,
        // placement: "bottom",
        template: '<div class="popover reactions-menu" ><div id="reactions-menu" data-id="'+element_id+'" class="popover-inner"><div class="popover-body"><div></div></div></div></div>',
        html: true,
				animation: false,
				// boundary: el,
				sanitize: false,
        content: card_popover.html()
      });
	    $('#reactions-menu').hide();
	    el.popover("show");
	    $('#reactions-menu .reaction').tooltip({
	      placement: "bottom",
	      template: '<div class="tooltip reaction-tooltip"><div></div><div class="tooltip-inner"></div></div>'
	    });
	    $('#reactions-menu').on("click", ".reaction", function() {
	      var card_reactions, count, elt, r, reaction, reactions, ref, ref1, socket_params, url_host, user_ids;
	      r = $(this);
	      reactions = $(this).closest('.reactions');
	      comment_id = $(this).closest('.comment-box').attr('data-comment-id');
	      reaction = reactions.find('.user-reaction[data-emoji-id="' + r.attr("data-emoji-id") + '"]');
	      rooms_message_id = $(this).closest('.room-message-box').attr('data-message-id');
	      if (reaction.length <= 0) {
	        user_ids = [Breeze.current_user.id];
	        elt = '<span class="user-reaction" data-user-ids="' + user_ids + '" data-emoji-id="' + r.attr('data-emoji-id') + '"><span class="emoji"></span><span class="count">1</span></span>';
	        $(elt).insertBefore(reactions.find('.add-reaction'));
	        card_reactions = "";
	        $('#card_modal .card-owner-container .reactions .user-reaction').each(function() {
	          return card_reactions += $(this)[0].outerHTML;
	        });
	        $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .card-reactions-bubble-container .reactions').html(card_reactions);
	        socket_params = {
	          project: Breeze.project_token,
	          method: "reactions-create",
	          comment_id: comment_id,
	          card_id: Breeze.Card.id,
	          card_reaction_html: card_reactions,
	          comment_reaction_html: reactions.html(),
	          rooms_message_id: rooms_message_id
	        };
	        if (developement()) {
	          url_host = "https://app.breeze.test:3006";
	        } else {
	          url_host = "https://breeze-node-n8.herokuapp.com";
	        }
	        $.ajax({
	          type: "POST",
	          url: url_host + '/reactions',
	          queue: true,
	          data: {
	            auth_token: Breeze.current_user.token,
	            reaction: {
	              comment_id: comment_id,
	              emoji_id: r.attr("data-emoji-id"),
	              card_id: Breeze.Card.id,
	              rooms_message_id: rooms_message_id
	            }
	          },
	          success: function() {
	            if (Breeze.is_socket_connected()) {
	              return Breeze.socket.emit("message", socket_params);
	            }
	          }
	        });
	        $(document).trigger("breeze:page", "card_modal");
	        update_project_list_view_columns("reactions");
	      } else {
	        user_ids = (ref = reaction.attr('data-user-ids')) != null ? ref.split(",").map(Number) : void 0;
	        if (ref1 = Breeze.current_user.id, indexOf.call(user_ids, ref1) < 0) {
	          user_ids.push(Breeze.current_user.id);
	          reaction.attr('data-user-ids', user_ids);
	          count = reactions.find('.user-reaction[data-emoji-id="' + r.attr("data-emoji-id") + '"] .count');
	          count.text(Number(count.text()) + 1);
	          card_reactions = "";
	          $('#card_modal .card-owner-container .reactions .user-reaction').each(function() {
	            card_reactions += $(this)[0].outerHTML;
	          });
	          $('.card[data-card-id="' + Breeze.Card.id + '"] .badges .card-reactions-bubble-container .reactions').html(card_reactions);
	          socket_params = {
	            project: Breeze.project_token,
	            method: "reactions-create",
	            comment_id: comment_id,
	            card_id: Breeze.Card.id,
	            card_reaction_html: card_reactions,
	            comment_reaction_html: reactions.html()
	          };
	          if (developement()) {
	            url_host = "https://app.breeze.test:3006";
	          } else {
	            url_host = "https://breeze-node-n8.herokuapp.com";
	          }
	          $.ajax({
	            type: "PUT",
	            url: url_host + '/reactions/' + reaction.attr('data-reaction-id'),
	            queue: true,
	            data: {
	              auth_token: Breeze.current_user.token,
	              reaction: {
	                comment_id: comment_id,
	                card_id: Breeze.Card.id,
	                emoji_id: r.attr("data-emoji-id")
	              }
	            },
	            success: function() {
	              if (Breeze.is_socket_connected()) {
	                return Breeze.socket.emit("message", socket_params);
	              }
	            }
	          });
	          update_project_list_view_columns("reactions");
	        }
	      }
				
        el.removeData("bs.popover");
        $('#reactions-menu').parent().remove();
				
	      $('.tooltip').remove();
	    });
	  }
	  return false;
	});


};
