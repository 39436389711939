
import { Sortable, AutoScroll } from 'sortablejs';

import {hide_first_swimlane_clear, hide_first_swimlane } from 'mobile/utils/swimlane.js'
import {update_list_wip} from 'mobile/utils/wip.js'
import {stage_equal_heights} from 'mobile/utils.js'
import PullToRefresh from 'mobile/utils/pullrefresh.js';
import {init_pull_to_refresh} from 'mobile/utils/pull_to_refresh.js';


var indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$.fn.sortable = function (options) {
	var retVal,
		args = arguments;

	this.each(function () {
		var $el = $(this),
			sortable = $el.data('sortable');
		if (!sortable && (options instanceof Object || !options)) {
			sortable = new Sortable(this, options);
			$el.data('sortable', sortable);
		} else if (sortable) {
			if (options === 'destroy') {
				sortable.destroy();
				$el.removeData('sortable');
			} else if (options === 'widget') {
				retVal = sortable;
			} else if (typeof sortable[options] === 'function') {
				retVal = sortable[options].apply(sortable, [].slice.call(args, 1));
			} else if (options in sortable.options) {
				retVal = sortable.option.apply(sortable, args);
			}
		}
	});

	return (retVal === void 0) ? this : retVal;
};

function make_sortable() {
  var scroll_left, scroll_right, scroll_vertical, sort_opts_card, sort_opts_stage, sort_opts_swimlane;
  sort_opts_swimlane = {
    tolerance: 'pointer',
    items: '> .swimlane',
    delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : void 0,
    forcePlaceholderSize: true,
    create1: function(e, ui) {
      var list;
      list = this;
      jQuery(list).css("min-height", jQuery(list).height());
    },
    start: function(e, ui) {
      ui.placeholder.height(ui.item.height());
      ui.placeholder.width(ui.item.width() - 20);
      if ($('.project-board.project-list-view').length > 0) {
        return $(".ui-sortable-placeholder").css({
          "width": "890px",
          "margin-left": "auto",
          "margin-right": "auto"
        });
      }
    },
    sort: function(e, ui) {
      if (e.clientY <= 50 && ui.offset.top >= 50) {
        return scroll_vertical(-20);
      } else if (e.clientY >= Breeze.view_height - 50 && ui.offset.top <= Breeze.sview_height + 150) {
        return scroll_vertical(20);
      }
    },
    update: function(event, ui) {
      var item_id, ltoken, prev_id;
      item_id = ui.item.attr("data-swimlane-id");
      prev_id = ui.item.prev().attr("data-swimlane-id");
      ltoken = Breeze.project_token;
      return $.ajax({
        type: "PUT",
        url: "/projects/" + Breeze.project + "/swimlanes/" + item_id + "/move",
        queue: true,
        data: {
          prev_id: prev_id
        }
      });
    }
  };
  sort_opts_stage = {
    tolerance: 'pointer',
    delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : void 0,
    items: '> .stage_class',
    start: function(event, ui) {
      if ($('.project-board.project-list-view').length <= 0) {
        return $(".ui-sortable-placeholder").css({
          "width": ui.item.width()
        });
      }
    },
    update: function(event, ui) {
      var item_id, ltoken, prev_id;
      item_id = ui.item.attr("data-stage-id");
      prev_id = ui.item.prev().attr("data-stage-id");
      ltoken = Breeze.project_token;
      if ($('.sortable_stage').length >= 2) {
        $('.sortable_stage').each(function() {
          if (prev_id != null) {
            return $(this).find('.stage_class[data-stage-id="' + item_id + '"]').not(ui.item).insertAfter($(this).find('.stage_class[data-stage-id="' + prev_id + '"]'));
          } else {
            return $(this).find('.stage_class[data-stage-id="' + item_id + '"]').not(ui.item).insertBefore($(this).find('.stage_class:first'));
          }
        });
      }
      return $.ajax({
        type: "POST",
        url: "/stages/move",
        queue: true,
        data: {
          stage_id: item_id,
          prev_id: prev_id,
          project_id: Breeze.project
        },
        success: function() {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", {
              project: ltoken,
              method: "stage-move",
              stage: item_id,
              message: prev_id
            });
          }
        }
      });
    }
  };
  sort_opts_card = {
		group: 'sortable',
		ghostClass: 'ui-sortable-placeholder',
		draggable: '.card',
		animation: 100,
		delayOnTouchOnly: true,
    delay: 200,
		filter: '.task-new-stage1, .card-placeholder',
		scroll: document.getElementsByClassName("card-sort")[0],
		scrollSpeed: 20,
		scrollSensitivity: 100,
    onStart: function(evt) {
			
			PullToRefresh.destroyAll()
			$(".card-sort").css("scroll-snap-type","none")
			// $(".card-sort").css("-webkit-scroll-snap-type","none")
			// $(".card-sort .sortable_stage").addClass("no-snap")
      Breeze.old_list = $(evt.item).parent().parent();
      Breeze.old_swimlane_id = $(evt.item).parent().parent().parent().attr('data-swimlane-id');
			if ($('.project-list-view').length <= 0) {
			$(".card-sort .task-new-stage").css("display","none") 
			$(".card-sort .stage_class .sortable").addClass("dragging-pad")
				
				
			}
			
			// $("body").addClass("no-select")
    },
    over: function(e, ui) {
      var el, project_ids, ref, stage;
      if (Breeze.current_user.has_board_droppable && $('.workspace.task-board').length > 0) {
        stage = $(this).closest('.stage_class');
        project_ids = stage.data('stage-project-ids');
        if (ref = ui.item.data('project-id'), indexOf.call(project_ids, ref) < 0) {
          stage.addClass("not-droppable");
        }
      }
      if ($('.swimlane').length > 0) {
        el = $(this).closest('.sortable_stage');
        if (el.hasClass('empty-stage')) {
          return el.css('min-height', ui.placeholder.height() + 40);
        }
      }
    },
		onMove1: function(evt) {
			console.log(evt.related)
			return 1;
		},
    scrollFn1: function(offsetX, offsetY, originalEvent, touchEvt, hoverTargetEl) {
			console.log(originalEvent.clientX)
			// console.log(originalEvent.offsetY)
      if (originalEvent.clientY <= 150 ) {
        scroll_vertical(-50);
      } else if (originalEvent.clientY >= Breeze.view_height - 50) {
        scroll_vertical(50);
      }
			// console.log(offsetY)
			// console.log(offsetX)
			// console.log(originalEvent.offsetY)
			// console.log(originalEvent.offsetX)
			// console.log(originalEvent)
			
			var pos = $('.card-sort').scrollLeft()
			var inc = 20
			
      if (originalEvent.clientX <= 90) {
				$('.card-sort').scrollLeft(pos-inc)
        scroll_left();
      } else if (originalEvent.clientX >= (Breeze.view_width - 90)) {
				
				$('.card-sort').scrollLeft(pos+inc)
        scroll_right();
      }
			return false
    },
    onEnd: function(evt) {
			// $(".card-sort").css("scroll-snap-type","x mandatory")
			
		  // setTimeout((function() {
		  //   $(".card-sort").css("scroll-snap-type","x mandatory")
		  // }), 600);
			// $("body").addClass("no-select")
			// $(".card-sort").css("scroll-snap-type","x mandatory")
			// $(".card-sort").css("-webkit-scroll-snap-type","x mandatory")
			
			$(".card-sort .task-new-stage").css("display","")
			$(".card-sort .stage_class .sortable").removeClass("dragging-pad")
			
			
      var item_id, ltoken, new_c, new_list, old_c, old_list, parent, position, prev, prev_el, project_ids, ref, ref1, ref2, ref3, socket_params, stage, stage_t_id, swimlane;
      if (Breeze.current_user.has_board_droppable && $('.workspace.task-board').length > 0) {
        stage = $(evt.item).parent().parent();
        project_ids = stage.data('stage-project-ids');
        $('.stage_class').removeClass('not-droppable');
        if (ref = $(evt.item).data('project-id'), indexOf.call(project_ids, ref) < 0) {
          return false;
        }
      }
      item_id = $(evt.item).attr("data-card-id");
      parent = $(evt.item).parent().parent();
      prev_el = $(evt.item).prev();
			
			if (prev_el && (prev_el.hasClass('card-placeholder') || prev_el.hasClass('task-new-stage') )) {
				$(evt.item).insertBefore(parent.find('.card-placeholder'))
			}
      if (prev_el && prev_el.hasClass('card-placeholder')) {
        prev_el = prev_el.prev();
      }
			
      prev = prev_el.attr("data-card-id");
			var prev_card_pos = $(evt.item).prev().attr('data-card-position')
			if(prev_card_pos) {
	      position = parseInt(prev_card_pos,10) + 1;
			}
			
      swimlane = $(evt.item).closest('.sortable_stage').attr('data-swimlane-id');
      ltoken = Breeze.project_token || $(evt.item).attr("data-project-token");
      if ($('.project-board').length <= 0) {
        Breeze.project_stages = (ref1 = _.where(Breeze.projects_stages, {
          name: $(evt.item).attr('data-project-id')
        })[0]) != null ? ref1.stages : void 0;
        Breeze.project_users = Breeze.all_team_users;
        stage_t_id = (ref2 = _.where(Breeze.project_stages, {
          label: parent.attr('data-stage-id')
        })[0]) != null ? ref2.id : void 0;
        Breeze.project_swimlanes = (ref3 = _.where(Breeze.projects_stages, {
          name: $(evt.item).attr('data-project-id')
        })[0]) != null ? ref3.swimlanes : void 0;
      } else {
        stage_t_id = parent.attr('data-stage-id');
      }
      $(document).trigger("breeze:page", ["update_stage_card", item_id, stage_t_id, Breeze.old_list.attr('data-stage-id'), $(evt.item)]);
      if (Breeze.old_swimlane_id && ((swimlane == null) || swimlane === 0)) {
        $(document).trigger("breeze:page", ["update_stage_card", item_id, stage_t_id, 0, $(evt.item)]);
      }
      $(document).trigger("breeze:page", ["update_swimlane_card", item_id, swimlane, Breeze.old_swimlane_id, $(evt.item)]);
      old_list = $('.sortable_stage .stage_class[data-stage-id="' + Breeze.old_list.attr('data-stage-id') + '"]');
      new_list = $('.sortable_stage .stage_class[data-stage-id="' + parent.attr('data-stage-id') + '"]');
      new_c = new_list.find('.card[data-status-id=""]').length;
      old_c = old_list.find('.card[data-status-id=""]').length;
      old_list.attr('data-wip-total', (old_c < 0 ? 0 : old_c));
      new_list.attr('data-wip-total', (new_c < 0 ? 0 : new_c));
      update_list_wip();

      if (parent.find('.card').length === 1) {
        parent.find('.task-new-stage');
        parent.find('.sortable').append(parent.find('.task-new-stage'));
      }
      $('.task-new-stage').show().css('visibility', '');
      parent.parent().css('min-height', parent.parent().height());
      $(evt.item).attr('data-stage-id', parent.attr("data-stage-id"));
      if (Breeze.old_swimlane_id !== swimlane) {
        if ($('.sortable_stage:first .card').length <= 0) {
          hide_first_swimlane_clear();
        } else {
          hide_first_swimlane_clear();
        }
      } else {
        hide_first_swimlane();
      }
      if (!(Breeze.old_list.attr('data-hidden') === "true") && ($(evt.item).attr('data-hidden') === "true")) {
        $(evt.item).attr('data-hidden', 'true');
      }
      if (parent.attr('data-hidden') === "true") {
        $(evt.item).attr('data-hidden', $(evt.item).attr('data-hidden'));
      }
      if ((Breeze.old_list.attr('data-hidden') === "true") && !(parent.attr('data-hidden') === "true")) {
        $(evt.item).attr('data-hidden', 'false');
      }
			
			init_pull_to_refresh()
			
      socket_params = {
        project: ltoken,
        method: "card-move",
        card: item_id,
        parent: parent.attr("data-stage-id"),
        message: prev,
        hidden: parent.data("hidden"),
        swimlane: swimlane,
        status_id: $(evt.item).attr('data-status-id'),
        status_name: $(evt.item).find('.text-overlay').text(),
        status_color: $(evt.item).find('.text-overlay').css('background-color')
      };
      $.ajax({
        type: "POST",
        url: "/cards/move",
        queue: true,
        data: {
          card_id: item_id,
          prev_id: prev,
          next_id: $(evt.item).next().attr("data-card-id"),
          parent_id: parent.attr("data-stage-id"),
          project_id: $(evt.item).attr('data-project-id'),
          position: position,
          swimlane: swimlane,
          master_board: $('.workspace').hasClass('task-board') ? true : void 0
        },
        success: function() {
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        }
      });
    }
  };
  scroll_right = function(d, a) {
    var next_button;
    if (d == null) {
      d = 50;
    }
    if (a == null) {
      a = 100;
    }
    $(".carousel-control.prev").show();
    Breeze.scroller += d;
    next_button = $('[data-slide="next"]');
    if (Breeze.scroller > Breeze.scroller_max) {
      Breeze.scroller = Breeze.scroller - (Breeze.scroller - Breeze.scroller_max);
    }
    if ($(window).scrollTop() > Breeze.stickyHeaderTop) {
      $(".stage_name").css("margin-left", -$(".card-sort").scrollLeft());
    }
    if (Breeze.scroller >= Breeze.scroller_max) {
      if (!($('.task-board').length > 0 && $(".carousel-control.next").attr('href').length > 0)) {
        next_button.hide();
      }
      return false;
    }
    next_button.show();
  };
  scroll_vertical = function(d) {
    if (Breeze.vscroller === void 0) {
      Breeze.vscroller = $(window).scrollTop();
    }
    Breeze.vscroller += d;
    return $(window).scrollTop(Breeze.vscroller);
  };
  scroll_left = function(d, a) {
    var prev_button;
    if (d == null) {
      d = 50;
    }
    if (a == null) {
      a = 100;
    }
    $(".carousel-control.next").show();
    prev_button = $('[data-slide="prev"]');
    Breeze.scroller -= d;
    if (Breeze.scroller < 0) {
      Breeze.scroller = 0;
    }
    if ($(window).scrollTop() > Breeze.stickyHeaderTop) {
      $(".stage_name").css("margin-left", -$(".card-sort").scrollLeft());
    }
    if (Breeze.scroller <= 0) {
      prev_button.hide();
      return false;
    }
    prev_button.show();
  };
  if ((Breeze.current_user.observer_move && Breeze.current_user.observer) || !Breeze.current_user.observer) {
		
		$('.sortable').sortable(sort_opts_card);
		
		// var el = document.getElementsByClassName('sortable')[0];
		// Sortable.create(el)
    // $(".sortable").sortable(sort_opts_card).sortable({
    //   connectWith: ".stage_class:not(.collapsed-stage) .connectedSortable"
    // });
    //
    // if ($('.project-board').length > 0) {
    //   $(".sortable_stage").sortable(sort_opts_stage);
    //   return $(".card-sort").sortable(sort_opts_swimlane);
    // }
  }
};

export {make_sortable};