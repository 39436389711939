import {update_list_wip} from 'mobile/utils/wip.js'
import {stage_equal_heights} from 'mobile/utils.js'
import {update_project_list_view_columns} from 'mobile/utils/project_board.js'
import {init_board_list_view} from 'mobile/utils.js'

var init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "project") {
      return init_page();
    }
  });
  if ($('.workspace.project-board').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('.project-board ').on("click", ".task-new-stage", function(e) {
		
	  var el;
				
    if ($('.card-inline-create').length > 0) {
      if ($('.card-inline-create textarea').val().trim().length > 0) {
        $('.card-inline-create .save').trigger("click");
      } else {
        $('.card-inline-create').remove();
      }
    }
    // if ((!Breeze.current_user.create_task_window && (!Breeze.current_user.create_task_window && !e.shiftKey)) || (e.shiftKey && Breeze.current_user.create_task_window)) {
		
    if (true) {
      el = '<div class="card-inline-create"> <textarea rows="2"  placeholder="Task name" class=""></textarea> <div class="actions"> <button class="c-button c-button--blue c-button-med c-button-save save" data-action="update" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Add task <div class="progress-button"> <div class="indeterminate"></div> </div> </button><button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> </div></div>';
      $(el).insertBefore($(this));
      $('.card-inline-create textarea').autosize().trigger('autosize.resize');
      $('.card-inline-create textarea').focus();
      $('.card-inline-create').on("keydown", "textarea", function(e, item) {
        var append_new;
        if (e.keyCode === 13) {
          $('.card-inline-create .save').trigger("click", append_new = true);
          return false;
        } else if (e.keyCode === 27) {
          $('.card-inline-create').remove();
          return false;
        }
      });
      $('.card-inline-create').on("click", ".cancel", function() {
        return $(this).closest('.card-inline-create').remove();
      });
      $('.card-inline-create').on("click", ".save", function(e, append_new) {
        var elt, name, stage, stage_id, swimlane, lproject,socket_params;
				
				elt = $(this)
				
			  lproject = lproject || Breeze.project;
			  lproject = lproject || Breeze.Card.project;
			  lproject = lproject || $('#card_project_id').val();
				
        name = $('.card-inline-create textarea').val().trim();
        if (name.length <= 0) {
          return false;
        }
        swimlane = $(this).closest('.sortable_stage');
        stage_id = $(this).closest('.stage_class').attr('data-stage-id');
        stage = $(this).closest('.stage_class');
        
				elt.prop('disabled', true);
        elt.find('.progress-button').show();
				
				
        // Breeze.create_new_task(stage_id, swimlane, hover_card = false, name, duedate = null, invitees = false, todo_action = true, todo_list_id = null);
        
			  socket_params = {
			    project: Breeze.project_token != null ? Breeze.project_token : hover_card.attr('data-project-token'),
			    method: "add-task",
			    swimlane: swimlane.attr('data-swimlane-id')
			  };
			  // $(document).trigger("breeze:page", ["update_stage_card", temp_card, stage, null, $('.card[data-card-id=' + temp_card + ']')]);
			  // $(document).trigger("breeze:page", ["update_swimlane_card", temp_card, swimlane.attr('data-swimlane-id'), null, $('.card[data-card-id=' + temp_card + ']')]);
			
			  $.ajax({
			    type: "POST",
			    url: '/cards.js',
			    queue: true,
			    data: {
			      card: {
			        name: name || "Task name",
			        project_id: lproject
			      },
			      swimlane: swimlane.attr('data-swimlane-id'),
			      stage: stage_id,
						inline: true
			    },
			    error: function(data) {
			      if (data.status !== 200) {
		
			        $.flashMessage("<span class='red-text'>There was an error. Please refresh your browser window.</span>", true);
			        Bugsnag.notify('Task add failed', 'Response: ' + data.responseText + ' Code:' + data.status + ' Status:' + data.statusText, {}, 'error');
			        if ($('.card-inline-create').length > 0) {
			          $('.card-inline-create .c-button[type="submit"]').prop('disabled', false);
			          $('.card-inline-create .c-button[type="submit"] .progress-button').hide();
			        }
			      }
			    },
			    success: function(data) {
						
			      if (data.length > 0) {
							var t;
							
						  if (stage) {
					      swimlane.find('.stage_class[data-stage-id="' + stage_id + '"] .sortable .task-new-stage').before(data);
						  } else {
						    swimlane.find(".stage_class:first-child .sortable .task-new-stage").before(data);
						  }
						  swimlane.find('.stage_class').equalHeights();
							
							
						  t = parseInt($('.sortable_stage:first .stage_class[data-stage-id="' + stage_id + '"]').attr('data-wip-total'), 10);
						  $('.sortable_stage:first .stage_class[data-stage-id="' + stage_id + '"]').attr('data-wip-total', t + 1);
						  update_list_wip($('.sortable_stage:first .stage_class[data-stage-id="' + stage_id + '"]'));
							
							elt.closest('.card-inline-create').remove();
			        stage_equal_heights(swimlane);
			        if (append_new) {
			          stage.find('.task-new-stage').trigger("click");
			        }
							
							socket_params.message = data;
			        if (Breeze.is_socket_connected()) {
			          Breeze.socket.emit("message", socket_params);
			        }
							init_board_list_view()
			      } else {
			        $.flashMessage("<span class='red-text'>We had problems creating the task. Please refresh your browser window.</span>", true);
			      }
			    }
			  });
				
				
				

        return false;
      });
      return false;
    }
  });
};
