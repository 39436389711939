import {developement} from 'mobile/utils.js';
import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';
var init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "rooms") {
      return init_page();
    }
  });
  if ($('.workspace.rooms').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('.rooms').on("submit", '#room-message-edit', function(e, item) {
    var attach_ids, el, i, id, mentions, message, message_id, ref, scanned_mentions, socket_params, url;
    el = $(this);
    message = $('#room-message-edit #editor-element').froalaEditor('html.get');
    if (message.length <= 0) {
      return false;
    }
    id = $('.chat-right-content-room').attr('data-room-id');
    message_id = $('#room-message-edit').attr('data-message-id');
    if (developement()) {
      url = "https://app.breeze.test:3006";
    } else {
      url = "https://breeze-node-n3.herokuapp.com";
    }
    mentions = [];
    scanned_mentions = [];
    $('<div>' + message + '</div>').find('.atwho-inserted').each(function() {
      return scanned_mentions.push($(this).text());
    });
    ref = Breeze.mentions;
    for (i in ref) {
      e = ref[i];
      if (_.contains(scanned_mentions, e.name)) {
        mentions.push(e.id);
      }
    }
    attach_ids = [];
    $('#editor-element .fr-view [data-attach-id]').each(function() {
      return attach_ids.push($(this).attr('data-attach-id'));
    });
    socket_params = {
      project: $('.chat-left-room[data-room-id="' + id + '"]').attr('data-room-token') || Breeze.current_user.room_tokens[0],
      room_id: id,
      message_id: message_id,
      method: "room-message-update"
    };
    $.ajax({
      type: "PUT",
      url: url + "/rooms/" + id + "/room_messages/" + message_id + "?auth_token=" + Breeze.current_user.token,
      data: {
        message: message,
        mention_ids: mentions,
        attach_ids: attach_ids
      },
      success: function(data) {
        socket_params.message = $('.room-message-box[data-message-id="' + message_id + '"]').prop('outerHTML');
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    $('.room-message-box[data-message-id="' + message_id + '"] .room-message-content-message').html(message);
    if ($('.room-message-box[data-message-id="' + message_id + '"] .room-message-content .room-message-edited').length <= 0) {
      $('.room-message-box[data-message-id="' + message_id + '"] .room-message-content').append('<div class="room-message-edited">Edited</div>');
    }
    $('.room-message-box .wysi-placeholder').remove();
    $('.room-message-content').show();
    embed_images($('.room-message-box[data-message-id="' + message_id + '"] .room-message-content-message'));
    $('#editor-element-create').attr("id", "editor-element");
		$('#project-messages-modal').removeClass("editing")
		
    return false;
  });
  $('.rooms').on("click", '.message-edit-action', function() {
    var container, el, message, message_id, popover_html;
    el = $(this);
    message = $(this).closest('.room-message-box');
    message_id = message.attr('data-message-id');
		
    if ($('.message-action-menu[data-id="'+message_id+'"]').length > 0) {
      el.popover("hide");
      el.removeData("popover");
      $('.message-action-menu').remove();
    } else {
      el.removeData("popover");
      $('.message-action-menu').remove();
      popover_html = $('<div> <ul class="dropdown-menu"> <li><a href="#" data-action="edit">Edit</a></li> <li class="divider"><a href="#"></a></li> <li><a href="#" data-action="delete"><span class="fa fa-trash-o" aria-hidden="true"></span>Delete</a></li> </ul> </div>');
      container = "elementbefore0";
      el.popover({
        trigger: "manual",
        container: el,
        // placement: "bottomleft",
        template: '<div class="popover todo-menu message-action-menu" data-id="' + message_id + '"><div class="popover-inner"><div class="popover-body dropdown-check"><div></div></div></div></div>',
        html: true,
				sanitize: false,
				animation: false,
        content: popover_html.html()
      });
      el.popover("show");
			
      $('.message-action-menu').on("click", '[data-action="edit"]', function() {
        var editor, message_box;
        $('.room-message-box .wysi-placeholder').remove();
        $('.room-message-content').show();
        unembed_images($('.room-message-box[data-message-id="' + message_id + '"] .room-message-content-message'));
        message_box = $('.room-message-box[data-message-id="' + message_id + '"]');
        message = message_box.find('.room-message-content-message').html();
        editor = '<div class="wysi-placeholder "> <form id="room-message-edit" data-message-id="' + message_id + '"> <div id="editor-element" placeholder="Message ' + $('.room-name').text() + '" name="room[message]"></div> <div class="submit space10"> <button class="c-button c-button--blue c-button-small" type="submit"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Save <div class="progress-button"> <div class="indeterminate"></div> </div> </button> <button class="c-button c-button--white c-button-small cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> </div> </form> </div>';
        message_box.find('.room-message-content').hide();
        $('#editor-element').attr("id", "editor-element-create");
        message_box.append(editor);
        $('#editor-element').on('froalaEditor.initialized', function(e, editor) {
          editor_setup(editor);
          // return $("#editor-element").froalaEditor('events.focus');
        });
        froala_setup.scrollableContainer = 'body';
        froala_setup.heightMin = 60;
        $('#editor-element').froalaEditor(froala_setup);
        $("#editor-element").froalaEditor("html.set", message);
        // $("#editor-element").froalaEditor('events.focus');
				
				$("#project-messages-modal #editor-element").on('froalaEditor.focus', function (e, editor) {
					// console.log("ffff222")
					$('#project-messages-modal').addClass("editing")
				});
	
				$("#project-messages-modal #editor-element").on('froalaEditor.blur', function (e, editor) {
					// console.log("bbbb")
					$('#project-messages-modal').removeClass("editing")
				});
	
	
        $('.message-action-menu').remove();
        $('#room-message-edit').on("click", ".cancel", function() {
          $('.room-message-box .wysi-placeholder').remove();
          $('.room-message-content').show();
          update_editor_attachments();
          embed_images(message_box.find('.room-message-content-message'));
					$('#project-messages-modal').removeClass("editing")
					
          return $('#editor-element-create').attr("id", "editor-element");
        });
        return false;
      });
      $('.message-action-menu').on("click", '[data-action="delete"]', function() {
        var room_id, socket_params, url;
        room_id = $('.chat-right-content-body').attr('data-room-id');
        if (developement()) {
          url = "https://app.breeze.test:3006";
        } else {
          url = "https://breeze-node-n3.herokuapp.com";
        }
        socket_params = {
          project: $('.chat-left-room[data-room-id="' + room_id + '"]').attr('data-room-token') || Breeze.current_user.room_tokens[0],
          room_id: room_id,
          message_id: message_id,
          method: "room-message-delete"
        };
        $.ajax({
          type: "DELETE",
          queue: true,
          url: url + '/rooms/' + room_id + '/room_messages/' + message_id + '?auth_token=' + Breeze.current_user.token,
          success: function(data) {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        $('.room-message-box[data-message-id="' + message_id + '"]').remove();
        if ($('#project-messages-modal').length > 0 && $('.room-message-box').length <= 0) {
          $('.chat-right-content-body').append('<div class="room-message-empty"><span>This is a place to discuss and leave message to everybody in the project</span></div>');
        }
        $('.message-action-menu').remove();
        return false;
      });
    }
    return false;
  });
};

// ---
// generated by coffee-script 1.9.2