import PullToRefresh from 'mobile/utils/pullrefresh.js';

// Pull to refresh
// ---------------
let shouldRefresh = true;


$(document).ready(function() {

document.body.addEventListener('touchstart', (ev) => {
  let el = ev.target;
  while (el.parentNode && el !== document.body) {
    if (el.scrollTop > 0) {
      shouldRefresh = false;
    }
    el = el.parentNode;
  }
});

document.body.addEventListener('touchend', (e) => {
  shouldRefresh = true;
});

});

function init_pull_to_refresh() {
	if (!window.navigator.standalone) {
		const ptr = PullToRefresh.init({
			distIgnore: 50,
			distMax: 120,
			distThreshold: 100,
			iconArrow: '<div class="m-loader"><svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" stroke-width="3.6" stroke-miterlimit="10"></circle></svg></div>',
			iconRefreshing: '<div class="m-loader"><svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" stroke-width="3.6" stroke-miterlimit="10"></circle></svg></div>',
			instructionsPullToRefresh: ' ',
			instructionsReleaseToRefresh: ' ',
			instructionsRefreshing: ' ',
			shouldPullToRefresh: () => !window.scrollY && shouldRefresh,
		  onRefresh() {
		    window.location.reload();
		  }
		});
	}


}
export {init_pull_to_refresh};
