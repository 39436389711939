require("mobile/froala/froala_editor.js")
require("mobile/froala/plugins/align.min.js")
require("mobile/froala/plugins/checklist.js")
require("mobile/froala/plugins/code_block.js")
require("mobile/froala/plugins/lists.js")
require("mobile/froala/plugins/colors.min.js")
require("mobile/froala/plugins/quote.js")
require("mobile/froala/plugins/url.js")
require("mobile/froala/plugins/video.js")
require("mobile/froala/plugins/emoticons.js")
require("mobile/froala/plugins/font_size.min.js")
require("mobile/froala/plugins/file.js")
require("mobile/froala/plugins/image.js")
require("mobile/froala/plugins/link.js")
require("mobile/froala/plugins/video.js")
require("mobile/froala/plugins/entities.min.js")
require("mobile/froala/plugins/draggable.min.js")
require("mobile/froala/plugins/table.js")

require("mobile/jquery.atwho.js")


				
// var Breeze = Breeze || {}

function init_froala_setup() {
  $.FroalaEditor.DefineIcon('linkTrash', {
    NAME: 'trash'
  });
  $.FroalaEditor.RegisterCommand('linkTrash', {
    title: 'Delete Link',
    undo: false,
    refresh: function($btn) {
      var link;
      link = this.link.get();
      if (link) {
        return $btn.removeClass('fr-hidden');
      } else {
        return $btn.addClass('fr-hidden');
      }
    },
    callback: function(cmd, val) {
      if (this.link) {
        return this.link.remove();
      }
    }
  });
  $.FroalaEditor.DefineIcon('linkImageOpen', {
    NAME: 'external-link'
  });
  $.FroalaEditor.RegisterCommand('linkImageOpen', {
    title: 'Open Link',
    undo: false,
    callback: function(cmd, val) {
      var link;
      link = $(this.image.get()).attr("src");
      if (link) {
        return window.open(link);
      }
    }
  });
  $.FroalaEditor.DefineIcon('videoImageOpen', {
    NAME: 'external-link'
  });
  $.FroalaEditor.RegisterCommand('videoImageOpen', {
    title: 'Open Link',
    undo: false,
    callback: function(cmd, val) {
      var link;
      link = $(this.video.get()).find('video').attr("src");
      if (link) {
        return window.open(link);
      }
    }
  });
  $.FroalaEditor.DefineIcon('paperclip', {
    NAME: 'paperclip'
  });
  $.FroalaEditor.DefineIconTemplate('checklist_svg', '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tasks" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-tasks fa-w-16 fa-3x"><path fill="currentColor" d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" class=""></path></svg>');
  $.FroalaEditor.DefineIcon('eyeslash', {
    NAME: 'eye-slash'
  });
  $.FroalaEditor.RegisterCommand('eyeslash', {
    title: 'Hide comment from clients',
    icon: 'eyeslash',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function(cmd, val) {
      $(this.$tb).find('[data-cmd="eyeslash"]').toggleClass('fr-active-manual');
      Breeze.current_user.comments_hide_create = !Breeze.current_user.comments_hide_create;
      return true;
    }
  });
  $.FroalaEditor.RegisterCommand('paperclip', {
    title: 'Add files',
    focus: true,
    type: 'dropdown',
    undo: false,
    refreshAfterCallback: true,
    options: {
      computer: "Upload"
      // gdrive: "Google Drive",
      // dropbox: "Dropbox",
      // boxnet: "Box",
      // onedrive: "OneDrive",
      // evernote: "Evernote"
    },
    callback: function(cmd, val) {
      Breeze.froala_upload = true;
      if (val === "computer") {
        $('#froala-upload-file').trigger('click');
      } else if (val === "gdrive") {
        Breeze.attach_google(true);
      } else if (val === "dropbox") {
        Breeze.attach_dropbox(true);
      } else if (val === "boxnet") {
        Breeze.attach_boxnet(true);
      } else if (val === "onedrive") {
        Breeze.attach_onedrive(true);
      } else if (val === "evernote") {
        Breeze.attach_evernote(true);
      }
      return this.selection.save();
    }
  });
	
};

var froala_setup = {
	key: window.location.hostname == 'm.breeze.test' ? 'vB2D7E6nD5D4G4B2C3D7A4C2B4D3aC-7opC5e1qag1A-8bD3A-21oxx==' : 'vB2D7E6nD5D4G4B2C3D7A4C2B4D3aC-7opC5e1qag1A-8bD3yg==',
  inlineMode: false,
	toolbarButtons: ["bold", "italic", "underline","strikeThrough","fontSize","quote", "code","color","formatOL","formatUL","checklist","insertLink","emoticons","insertTable","clearFormatting","|","paperclip","eyeslash"],
	toolbarButtonsMD: ["bold", "italic", "underline","strikeThrough","fontSize","quote", "code","color","formatOL","formatUL","insertLink","emoticons","insertTable","clearFormatting","|","paperclip","eyeslash"],
	toolbarButtonsSM: ["bold", "italic", "underline","strikeThrough","fontSize","quote", "code","color","formatOL","formatUL","insertLink","emoticons","insertTable","clearFormatting","|","paperclip","eyeslash"],
	toolbarButtonsXS: ["bold", "underline","strikeThrough","fontSize","color","emoticons","clearFormatting","|","paperclip","eyeslash"],
  heightMin: 120,
  placeholderText: "",
  fontSize: ['8', '10', '12', '15', '18', '22', '30', '40'],
  htmlAllowedTags: ["video", "p", "h1", "h2", "h3", "h4", "h5", "h6", "strong", "b", "i", "em", "br", "p", "div", "ul", "ol", "li", "pre", "a", "blockquote", "img", "span", "u", "strike", "s", "input", "table", "td", "tr", "tbody", "thead", "th"],
  pasteDeniedTags: ['colgroup', 'col', 'meta', 'input'],
  linkText: false,
  wordPasteModal: false,
  spellcheck: true,
	autofocus: false,
  // scrollableContainer: ".modal",
  imageDefaultWidth: "auto",
  imageResizeWithPercent: true,
  paragraphFormat: {
    N: 'Normal',
    PRE: 'Code',
    H2: 'Heading'
  },
  toolbarSticky: false,
  requestWithCORS: false,
  imageDefaultDisplay: 'inline',
  imageMove: true,
  imageEditButtons: ['linkImageOpen', 'imageRemove'],
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkTrash'],
  linkInsertButtons: ['linkBack'],
  linkText: true,
  imageInsertButtons: ['imageUpload'],
  fileMaxSize: 1024 * 1024 * 5000,
  imageMaxSize: 1024 * 1024 * 5000,
  imageUploadToS3: {
    bucket: 'fec982a52',
    region: 's3',
    keyStart: generate_s3_key()
  },
  fileUploadToS3: {
    bucket: 'fec982a52',
    region: 's3',
    keyStart: generate_s3_key()
  },
  videoDefaultWidth: 400,
  videoMaxSize: 1024 * 1024 * 5000,
  videoDefaultDisplay: 'inline',
  videoEditButtons: ['videoImageOpen', 'videoRemove'],
  videoUploadToS3: {
    bucket: 'fec982a52',
    region: 's3',
    keyStart: generate_s3_key(),
    params: {
      utf8: '',
      AWSAccessKeyId: $("#AWSAccessKeyId").val(),
      policy: $("#policy").val(),
      signature: $("#signature").val(),
      'Cache-Control': $("#Cache-Control").val()
    }
  },
  blockTags: {
    n: 'Normal',
    pre: 'Quote',
    h2: 'Heading 1',
    h3: 'Heading 2'
  }
};

//
// $(document).on("click", ".card-description .breeze-uploaded-image", function(e) {
//   var idx, name, src;
//   src = $(this).attr('src');
//   idx = src.toLowerCase().lastIndexOf('\%2f');
//   if (idx === -1) {
//     idx = src.toLowerCase().lastIndexOf('/');
//   }
//   name = src.substring(idx + 1);
//   name = name.substring(name.indexOf('-') + 1);
//   $.magnificPopup.open({
//     closeBtnInside: false,
//     fixedContentPos: true,
//     mainClass: 'mfp-no-margins mfp-with-zoom',
//     items: {
//       src: src
//     },
//     type: 'image',
//     callbacks: {
//       afterClose: function() {
//         $("html").css("overflow-y", "hidden");
//         $("html").css("overflow", "hidden");
//         return $("html").css("-ms-overflow-y", "hidden");
//       }
//     },
//     image: {
//       titleSrc: function(item) {
//         return '<a href="' + src + '" target="_blank">' + name + '</a>';
//       }
//     }
//   });
//   return false;
// });
// $(document).on("click", ".boxes .attach-image", function(e) {
//   var sidx;
//   sidx = $(this).closest('.attach-box').index();
//   $(this).closest('.boxes').find('.attach-image').magnificPopup({
//     type: 'image',
//     closeBtnInside: false,
//     fixedContentPos: true,
//     mainClass: 'mfp-no-margins mfp-with-zoom',
//     gallery: {
//       enabled: true
//     },
//     callbacks: {
//       afterClose: function() {
//         $("html").css("overflow-y", "hidden");
//         $("html").css("overflow", "hidden");
//         return $("html").css("-ms-overflow-y", "hidden");
//       }
//     },
//     image: {
//       tError: '<a href="%url%">The file</a> could not be loaded.',
//       titleSrc: function(item) {
//         var idx, name;
//         if (item && item.src) {
//           idx = item.src.toLowerCase().lastIndexOf('\%2f');
//           if (idx === -1) {
//             idx = item.src.toLowerCase().lastIndexOf('/');
//           }
//           name = item.src.substring(idx + 1);
//           return '<a href="' + item.src + '" target="_blank">' + name + '</a>';
//         } else {
//           return '';
//         }
//       }
//     }
//   }).magnificPopup('open', sidx);
//   return false;
// });
// $(document).on("keydown", "#editor-element", function(e) {
//   if (e.keyCode === 67 && e.shiftKey && (e.ctrlKey || e.metaKey)) {
//     $('#editor-element').froalaEditor('commands.clearFormatting');
//     return false;
//   }
// });
// $('body').on('mouseup', '.atwho-view-ul li', function(e) {
//   e.stopPropagation();
// });


function find_editor_attachments() {
  Breeze.attach_before_ids = [];
  Breeze.attach_after_ids = [];
  Breeze.attach_inserted_ids = [];
  return $('.fr-view [data-attach-id]').each(function() {
    return Breeze.attach_before_ids.push($(this).attr('data-attach-id'));
  });
};
function find_editor_attachments_after() {
  var ids;
  ids = [];
  $('.fr-view [data-attach-id]').each(function() {
    return ids.push($(this).attr('data-attach-id'));
  });
  return ids;
};
function find_editor_attachments_update_delete(ids) {
  var idd, ids_not_in_text;
  ids_not_in_text = _.difference(Breeze.attach_inserted_ids, ids);
  idd = _.difference(Breeze.attach_before_ids, ids);
  return idd.concat(ids_not_in_text);
};


function update_editor_attachments() {
  var added_attach, deleted_attach;
  $('.fr-view [data-attach-id]').each(function() {
    return Breeze.attach_after_ids.push($(this).attr('data-attach-id'));
  });
  added_attach = Breeze.attach_before_ids;
  deleted_attach = _.difference(Breeze.attach_after_ids, Breeze.attach_before_ids).concat(Breeze.attach_inserted_ids);
  if (added_attach.length > 0 || deleted_attach.length > 0) {
    $.ajax({
      type: "PUT",
      queue: true,
      url: '/projects/' + Breeze.Card.project + '/cards/' + Breeze.Card.id + '/update_attach',
      data: {
        added_attach: added_attach,
        deleted_attach: deleted_attach
      }
    });
  }
  Breeze.attach_before_ids = [];
  Breeze.attach_after_ids = [];
  return Breeze.attach_inserted_ids = [];
};

function init_image_magnify(that) {
  var idx, name, src;
  src = that.attr('src');
  idx = src.toLowerCase().lastIndexOf('\%2f');
  if (idx === -1) {
    idx = src.toLowerCase().lastIndexOf('/');
  }
  name = src.substring(idx + 1);
  name = name.substring(name.indexOf('-') + 1);
  $.magnificPopup.open({
    closeBtnInside: false,
    fixedContentPos: true,
    mainClass: 'mfp-no-margins mfp-with-zoom',
    items: {
      src: src
    },
    type: 'image',
    callbacks: {
      afterClose: function() {
        $("html").css("overflow-y", "hidden");
        $("html").css("overflow", "hidden");
        return $("html").css("-ms-overflow-y", "hidden");
      }
    },
    image: {
      titleSrc: function(item) {
        return '<a href="' + src + '" target="_blank">' + name + '</a>';
      }
    }
  });
  return false;
};

function guid() {
  var _p8;
  _p8 = function(s) {
    var p;
    p = (Math.random().toString(16) + "000000000").substr(2, 8);
    if (s) {
      return "-" + p.substr(0, 4) + "-" + p.substr(4, 4);
    } else {
      return p;
    }
  };
  return _p8() + _p8(true) + _p8(true) + _p8();
};

function uniqueId(length) {
  var id;
  if (length == null) {
    length = 16;
  }
  id = "";
  while (id.length < length) {
    id += Math.random().toString(36).substr(2);
  }
  return id.substr(0, length);
};

function generate_s3_key() {
  if (Breeze.current_user && Breeze.current_user.team_id) {
    return Breeze.current_user.team_id + "/" + guid() + "/" + uniqueId() + "/";
  } else {
    return guid() + "/" + uniqueId() + "/";
  }
};

function editor_setup(element) {
  if (Breeze.mention_users && Breeze.mention_users.length > 1) {
    if (!_.findWhere(Breeze.mention_users, {
      id: -999
    })) {
      Breeze.mention_users.unshift({
        id: -999,
        name: "all",
        display: "all",
        email: "project users",
        fname: "all"
      });
    }
  }
  element.$el.atwho({
    at: "@",
    startWithSpace: true,
    data: Breeze.mention_users,
    searchKey: "display",
    editableAtwhoQueryAttrs: {
      "data-fr-verified": true
    },
    insertTpl: "<b>@${name}</b>",
    displayTpl: '<li data-value="@${name}" data-id="${id}" data-email="${email}" data-name="${fname}" data-symbol="@"><b>${fname}</b> ${email}</li>',
    maxLen: 100
  });
  Breeze.mentions = [];
  element.$el.atwho({
    at: "#",
    startWithSpace: true,
    editableAtwhoQueryAttrs: {
      "data-fr-verified": true
    },
    data: [],
    searchKey: "name",
    insertTpl: '<a href="https://app.breeze.pm/cards/${id}" class="card-link-ref">${label}</a>',
    tpl: '<li data-value="${label}" data-id="${id}" data-project_id="${project_id}" data-symbol="#">${label}</li>',
    maxLen: 100,
    limit: 10,
    callbacks: {
      remoteFilter: function(query, callback) {
        return $.ajax({
          type: "GET",
          url: '/projects/cards/search.json',
          data: {
            query: query,
            project: Breeze.project
          },
          success: function(data) {
            return callback(data);
          }
        });
      }
    }
  });
  element.$el.on("inserted.atwho", function(event, query) {
    if (query.data('symbol') === '@') {
      return Breeze.mentions.push({
        id: query.data('id'),
        name: query.data('value')
      });
    }
  });
  element.$el.on("shown.atwho", function(event, query) {
    return Breeze.atwho_open = true;
  });
  element.$el.on("hidden.atwho", function(event, query) {
    return Breeze.atwho_open = false;
  });
  element.events.on('keydown', (function(e) {
    // if ((e.keyCode === 13 && e.shiftKey) || (e.keyCode === 13 && e.ctrlKey)) {
    //   if ($('.card-editor .submit button[type="submit"]').length > 0) {
    //     $('.card-editor .submit button[type="submit"]').trigger("click");
    //   }
    //   if ($('.comment-editor .submit button[type="submit"]').length > 0) {
    //     $('.comment-editor .submit button[type="submit"]').trigger("click");
    //   }
    //   false;
    // }
		
    if (e.which === $.FroalaEditor.KEYCODE.ENTER && element.$el.atwho('isSelecting')) {
      return false;
    }
		if (($('.workspace.rooms').length > 0 || $('#project-messages-modal.active').length > 0) && $('#room-message-edit').length > 0 && (e.keyCode === 13 && !e.shiftKey)) {
		  $('#room-message-edit').submit();
		  e.preventDefault();
		  e.stopPropagation();
		  e.stopImmediatePropagation();
		  return false;
		} else if (($('.workspace.rooms').length > 0 || $('#project-messages-modal.active').length > 0) && $('#room-message-create').length > 0 && (e.keyCode === 13 && !e.shiftKey)) {
		  console.log("sssss")
			$('#room-message-create').submit();
		  e.preventDefault();
		  e.stopPropagation();
		  e.stopImmediatePropagation();
		  return false;
		}
		
    if (e.which === $.FroalaEditor.KEYCODE.ENTER) {
      // if (Breeze.froala_scroll_top_body != null) {
      //   $('body').scrollTop(Breeze.froala_scroll_top_body);
      // }
      // if (Breeze.froala_scroll_top_html != null) {
      //   $('html').scrollTop(Breeze.froala_scroll_top_html);
      // }
    }
  }), true);
  $('#editor-element').on("froalaEditor.image.error froalaEditor.file.error", function(e, editor, error, response) {
    var froala_file;
    try {
      froala_file = {
        'name': Breeze.froala_tmpfile[0].name,
        'size': Breeze.froala_tmpfile[0].size,
        'type': Breeze.froala_tmpfile[0].type,
        'lastModifiedDate': Breeze.froala_tmpfile[0].lastModifiedDate
      };
      Bugsnag.notify('Froala upload failed', 'Error: ' + JSON.stringify(error) + ' Response:' + response + ' FileS3:' + JSON.stringify(editor.opts.fileUploadToS3) + ' ImageS3:' + JSON.stringify(editor.opts.imageUploadToS3) + ' File:' + JSON.stringify(froala_file), {}, 'error');
    } catch (_error) {
      true;
    }
    return Breeze.get_s3form();
  });
	
	
	
	$('#editor-element').on("froalaEditor.image.beforePasteUpload", function(e, editor, img) {
	  var array, binary, file, i, s3form_date, time_now, upload_img;
	  if (Breeze.project_file_storage === "google") {
	    binary = atob($(img).attr('src').split(',')[1]);
	    array = [];
	    i = 0;
	    while (i < binary.length) {
	      array.push(binary.charCodeAt(i));
	      i++;
	    }
	    upload_img = new Blob([new Uint8Array(array)], {
	      type: 'image/jpeg'
	    });
	    file = new File([upload_img], "image.jpg");
	    $(img).remove();
	    Breeze.upload_gdrive_froala(e, [file]);
	    return false;
	  } else {
	    time_now = Math.round((Date.now()) / 1000);
	    s3form_date = parseInt($('#fileupload').attr('data-created-at'), 10);
	    if ((time_now - s3form_date) >= 3600) {
	      Breeze.get_s3form(false);
	    }
	    editor.opts.imageUploadToS3.keyStart = generate_s3_key();
	    return Breeze.froala_tmpfile = img;
	  }
	});

	$('#editor-element').on("froalaEditor.image.beforeUpload", function(e, editor, img) {
	  var s3form_date, time_now;
	  if (Breeze.project_file_storage === "google") {
	    Breeze.upload_gdrive_froala(e, img);
	    return false;
	  } else {
	    time_now = Math.round((Date.now()) / 1000);
	    s3form_date = parseInt($('#fileupload').attr('data-created-at'), 10);
	    if ((time_now - s3form_date) >= 3600) {
	      Breeze.get_s3form(false);
	    }
	    editor.opts.imageUploadToS3.keyStart = generate_s3_key();
	    return Breeze.froala_tmpfile = img;
	  }
	});

	$('#editor-element').on("froalaEditor.file.beforeUpload", function(e, editor, img) {
	  var s3form_date, time_now;
	  if (Breeze.project_file_storage === "google") {
	    Breeze.upload_gdrive_froala(e, img);
	    return false;
	  } else {
	    time_now = Math.round((Date.now()) / 1000);
	    s3form_date = parseInt($('#fileupload').attr('data-created-at'), 10);
	    if ((time_now - s3form_date) >= 3600) {
	      Breeze.get_s3form(false);
	    }
	    editor.opts.fileUploadToS3.keyStart = generate_s3_key();
	    return Breeze.froala_tmpfile = img;
	  }
	});
	
	
	
  $('#editor-element').on("froalaEditor.image.removed froalaEditor.file.unlink", function(e, editor, el) {
    var url;
    if ($(el).attr('data-project-id')) {
      if ($(el).attr('data-comment-id')) {
        url = '/projects/' + $(el).attr('data-project-id') + '/cards/' + $(el).attr('data-attach-card-id') + '/comments/' + $(el).attr('data-comment-id') + '/attachments/' + $(el).attr('data-attach-id');
      } else if ($(el).attr('data-card-id')) {
        url = '/projects/' + $(el).attr('data-project-id') + '/cards/' + $(el).attr('data-attach-card-id') + '/attachments/' + $(el).attr('data-attach-id');
      } else {
        url = '/projects/' + $(el).attr('data-project-id') + '/attachments/' + $(el).attr('data-attach-id');
      }
      $.ajax({
        type: 'DELETE',
        url: url,
        queue: true
      });
    }
    return $(el).remove();
  });
  $('#editor-element').on("froalaEditor.image.inserted froalaEditor.file.inserted", function(e, editor, $el, response) {
    var card, comment, link, name, name_parts, only_key, project, s3name, url;
    link = decodeURIComponent($el.attr("src") || $el.attr("href"));
    if (response && response['attach-id']) {
      $el.attr('data-project-id', response['project-id']).attr('data-comment-id', response['comment-id']).attr('data-attach-id', response['attach-id']).attr('data-attach-card-id', response['attach-card-id']);
    }
    if (Breeze.froala_upload_files && Breeze.froala_upload_files > 0) {
      Breeze.froala_upload_files = -1;
      if (Breeze.froala_upload_files <= 0) {
        $("#editor-element").froalaEditor('popups.hideAll');
      }
    }
    name_parts = link.split("/");
    only_key = name_parts[3] + "/" + name_parts[4] + "/" + name_parts[5] + "/";
    if (name_parts[2] === "fec982a52.s3.amazonaws.com") {
      name = link.substring(link.length - name_parts[6].length);
      s3name = encodeURIComponent(name);
      project = $('#editor-element').data("project") || Breeze.project;
      card = $('#editor-element').data("card");
      comment = $('#editor-element').data("comment");
			
			room_id = $('.chat-right-content-room').attr('data-room-id')
			message_id = $('#room-message-edit').attr('data-message-id') || 0
			
      if ($('#editor-element').data("comment")) {
        url = '/projects/' + project + '/cards/' + card + '/comments/' + comment + '/attachments';
      } else if ($('#editor-element').data("new_comment")) {
        url = '/projects/' + project + '/cards/' + card + '/comments/0/attachments';
      } else if ($('#project-description-modal').hasClass('in') ||  $('#project-notes-modal').hasClass('show')) {
        url = '/projects/' + project + '/attachments';
			} else if (room_id) {
			  url = "/rooms/" + room_id + "/room_messages/" + message_id + "/attachments";
			} else if ($('#editor-element').attr("data-editor") === "home-notes") {
			  url = "/attachment_personal_note";
			} else {
        url = '/projects/' + project + '/cards/' + card + '/attachments';
      }
      $.ajax({
        type: 'POST',
        url: url,
        queue: true,
        data: {
          attachment: {
            name: name,
            s3name: s3name,
            key: only_key,
            bucket: "fec982a52",
            embeded: true
          }
        },
        success: function(ret) {
          if ($('#project-description-modal').hasClass('in') || $('#project-notes-modal').hasClass('show')) {
            ret = $(ret).attr('data-attach-id');
          }
          $el.attr('data-project-id', project).attr('data-comment-id', comment).attr('data-attach-id', ret).attr('data-attach-card-id', card);
          return Breeze.attach_inserted_ids.push(ret);
        }
      });
    }
    return $('#editor-element').removeClass('hover');
  });
};

export {froala_setup, editor_setup,init_froala_setup, update_editor_attachments, find_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments_after,uniqueId,generate_s3_key};

