import {developement} from 'mobile/utils.js';
import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';

var init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "rooms") {
      return init_page();
    }
  });
  if ($('.workspace.rooms').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('.rooms').on("submit", '#room-message-create', function(e, item) {
    var attach_ids, avatar_img, date, el, i, id, last_el, mentions, message, message_box, ref, scanned_mentions, socket_params, temp_id, url;
    el = $(this);
    message = $('#room-message-create #editor-element').froalaEditor('html.get');
    if (message.length <= 0) {
      return false;
    }
    id = $('.chat-right-content-room').attr('data-room-id');
    temp_id = "message_" + Breeze.current_user.id + "_" + new Date().getTime() + _.random(1000);
    if (developement()) {
      url = "https://app.breeze.test:3006/rooms/" + id + "/room_messages?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n8.herokuapp.com/rooms/" + id + "/room_messages?auth_token=" + Breeze.current_user.token;
    }
		
		var dateFormat = require('dateformat');
		
    if (Breeze.clock24) {
      date = dateFormat(new Date(), "HH:MM");
    } else {
      date = dateFormat(new Date(), "HH:MMtt");
    }
    avatar_img = '<img src="' + Breeze.current_user.avatar + '" size="80" class="avatar_card" width="80" height="80">';
    message_box = '<div class="room-message-box" data-message-user-id="' + Breeze.current_user.id + '" data-message-id="' + temp_id + '"> <div class="room-message-avatar" style="background-color:#' + Breeze.current_user.color + '">' + avatar_img + '<p class="member_initials">' + Breeze.current_user.initials + '</p> </div> <div class="room-message-content"> <div class="room-message-content-header"><b>' + Breeze.current_user.name + '</b> <span class="room-message-date">' + date + '</span><span class="reactions"> <span class="add-reaction" title="Reactions"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" stroke="currentColor" stroke-width="5" d="M208 96C93.1 96 0 189.1 0 304s93.1 208 208 208 208-93.1 208-208S322.9 96 208 96zm0 384c-97 0-176-79-176-176s79-176 176-176 176 79 176 176-79 176-176 176zm75.8-130.7C265 371.4 237.4 384 208 384s-57-12.6-75.8-34.6c-5.7-6.7-15.9-7.5-22.5-1.8-6.8 5.8-7.5 15.8-1.8 22.6C132.7 399.3 169.2 416 208 416s75.3-16.7 100.2-45.9c5.8-6.7 4.9-16.8-1.8-22.6-6.6-5.6-16.8-4.9-22.6 1.8zM144 280c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zM632 96h-88V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v88h-88c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88v88c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-88h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z"></path></svg></span></span> <a href="#" class="message-edit-action"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-project-id="46266" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg></a> </div> <div class="room-message-content-message">' + message + '</div> </div> </div>';
    mentions = [];
    scanned_mentions = [];
    $('<div>' + message + '</div>').find('.atwho-inserted').each(function() {
      return scanned_mentions.push($(this).text());
    });
    ref = Breeze.mentions;
    for (i in ref) {
      e = ref[i];
      if (_.contains(scanned_mentions, e.name)) {
        mentions.push(e.id);
      }
    }
    attach_ids = [];
    $('#editor-element .fr-view [data-attach-id]').each(function() {
      return attach_ids.push($(this).attr('data-attach-id'));
    });
    socket_params = {
      project: $('.chat-left-room[data-room-id="' + id + '"]').attr('data-room-token') || Breeze.current_user.room_tokens[0],
      room_id: id,
      method: "room-message-create",
      project_message: $('#project-messages-modal').length > 0
    };
    $.ajax({
      type: "POST",
      url: url,
      data: {
        message: message,
        mention_ids: mentions,
        attach_ids: attach_ids
      },
      success: function(data) {
        $('.room-message-box[data-message-id="' + temp_id + '"]').attr('data-message-id', data);
        socket_params.message = $('.room-message-box[data-message-id="' + data + '"]').prop('outerHTML');
        if (Breeze.is_socket_connected()) {
          return Breeze.socket.emit("message", socket_params);
        }
      }
    });
    $('.chat-right-content-body').append(message_box);
    $("#editor-element").froalaEditor("html.set", " ");
    if ($('#project-messages-modal').length > 0 && $('.room-message-box').length > 0) {
      $('.room-message-empty').remove();
    }
    last_el = $('.room-message-box:last')[0];
    if (last_el) {
      last_el.scrollIntoView();
    }
		document.activeElement.blur();
		$('#project-messages-modal').removeClass("editing")
		
    return false;
  });
};

// ---
// generated by coffee-script 1.9.2