// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Breeze = window.Breeze || {}

Breeze.is_socket_connected = function() {
	return false;
	
}
//
// // require("@rails/ujs").start()
require("turbolinks").start()
import {} from 'jquery-ujs'
import 'popper.js';
// // import 'jquery';
//
import 'bootstrap';
// // import 'bootstrap/js/dist/util';
// // import 'bootstrap/js/dist/modal';
// // import 'bootstrap/js/dist/tooltip';
// // import 'bootstrap/js/dist/popover';
// //
// // import 'bootstrap/js/dist/dropdown';
//
// // import 'bootstrap/dist/css/bootstrap.min.css';
//
window['_'] = require('underscore')
window['$'] = require('jquery')
//
//
// // import "jquery-ui/ui/widgets/datepicker"
// // require("@rails/activestorage").start()
// // require("channels")
//
//
// // Uncomment to copy all static images under ../images to the output folder and reference
// // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// // or the `imagePath` JavaScript helper below.
// //
// // const images = require.context('../images', true)
// // const imagePath = (name) => images(name, true)
//
//
// // require("ganttile/dhtmlxgantt/dhtmlxgantt.js")
// // require("ganttile/dhtmlxgantt/export.js")
// // require("ganttile/dhtmlxgantt/ext/dhtmlxgantt_critical_path.js")
// // require("ganttile/dhtmlxgantt/ext/dhtmlxgantt_auto_scheduling.js")
//
// // require("ganttile/bootstrap/bootstrap-button.js")
// // require("ganttile/bootstrap/bootstrap-collapse.js")
// // require("ganttile/bootstrap/bootstrap-dropdown.js")
// // require("ganttile/bootstrap/bootstrap-modal.js")
// // require("ganttile/bootstrap/bootstrap-popover.js")
// // require("ganttile/bootstrap/bootstrap-tab.js")
// // require("ganttile/bootstrap/bootstrap-tooltip.js")
// // require("ganttile/bootstrap/bootstrap-transition.js")
// // require("ganttile/bootstrap/bootstrap-typeahead.js")
//
// // require("ganttile/bootstrap/multiselect.js")
//
// // require("ganttile/charts.js")
// // require("ganttile/jquery.autosize.js")
require("mobile/jquery-ui.min.js")
require("mobile/utils/md5.js")
require("mobile/utils/pullrefresh.js")

require("mobile/jquery.fileupload.js")

require("mobile/notifications.js")
require("mobile/search.js")

require("mobile/activities.js")
require("mobile/nav.js")
require("mobile/app.js")
//
//
require("mobile/workspaces/workspace-loader.js")
require("mobile/cards/cards.js")
require("mobile/comments/comments.js")
require("mobile/reactions.js")
require("mobile/todos/todo-lists.js")
require("mobile/time-entries.js")

require("mobile/projects/index.js")
require("mobile/projects/settings.js")
require("mobile/projects/name.js")
require("mobile/projects/users.js")
require("mobile/projects/stages.js")
require("mobile/projects/notes.js")
require("mobile/projects/messages.js")

require("mobile/rooms/loader.js")
require("mobile/rooms/index.js")
require("mobile/rooms/messages/create.js")
require("mobile/rooms/messages/edit.js")

require("mobile/projects/show.js")
require("mobile/utils/timer.js")
require("mobile/utils/jquery.ajax.queue.js")

require("mobile/bootstrap-multiselect.js")
require("mobile/cards/utils/move_to_archive.js")
//
require("mobile/cards/card-menu/status.js")
require("mobile/cards/card-menu/colors.js")
require("mobile/cards/card-menu/tag.js")
require("mobile/cards/card-menu/assign.js")
require("mobile/cards/card-menu/estimate.js")
require("mobile/cards/card-menu/custom_fields.js")
require("mobile/cards/card-menu/duedate.js")
require("mobile/cards/card-menu/files.js")
require("mobile/cards/card-menu/deps.js")
require("mobile/cards/card-menu/archive.js")
require("mobile/cards/card-menu/subscribe.js")

require("mobile/cards/estimates.js")

require("mobile/cards/card-tag.js")
require("mobile/cards/create-inline.js")
require("mobile/cards/card-move-short.js")

require("mobile/stages/stage-automation.js")
require("mobile/stages/automation/archive.js")
require("mobile/stages/automation/colors.js")
require("mobile/stages/automation/duedate.js")
require("mobile/stages/automation/status.js")
require("mobile/stages/automation/tags.js")
require("mobile/stages/automation/users.js")
//
require("mobile/attachments/attachments.js")
require("mobile/attachments/gdrive.js")

require("mobile/dashboard/dashboard.js")
require("mobile/dashboard/fields.js")
require("mobile/dashboard/home.js")
require("mobile/dashboard/modal.js")

require("mobile/swimlanes/swimlanes-loader.js")
require("mobile/swimlanes/automation/archive.js")
require("mobile/swimlanes/automation/colors.js")
require("mobile/swimlanes/automation/duedate.js")
require("mobile/swimlanes/automation/status.js")
require("mobile/swimlanes/automation/tags.js")
require("mobile/swimlanes/automation/users.js")
//
require("mobile/resumableupload_js.js")


// require('mobile/bootstrap.js');

// require("mobile/utils.js")

// require("mobile/underscore-min.js")

