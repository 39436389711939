import {developement} from 'mobile/utils.js';
import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';


var fetch_page, init_editor, init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "rooms") {
      return init_page();
    }
  });
  $(document).on("breeze:page", function(e, page) {
    if (page === "rooms-editor") {
      return init_editor();
    }
  });
  if ($('.workspace.rooms').length > 0) {
    init_page();
  }
  $(document).on("breeze:page", function(e, page) {
    if (page === "rooms-message") {
      return fetch_page();
    }
  });
  if ($('.workspace.rooms-empty').length > 0) {
    return fetch_page();
  }
});

fetch_page = function() {
  var url;
  if ($('.workspace.rooms-empty').length > 0) {
    if (developement()) {
      url = ("https://app.breeze.test:3006" + location.pathname + "?&auth_token=") + Breeze.current_user.token;
    } else {
      url = ("https://breeze-node-n3.herokuapp.com" + location.pathname + "?&auth_token=") + Breeze.current_user.token;
    }
    return $.ajax({
      type: 'GET',
      url: url,
      data: {
        partial: true
      },
      beforeSend: function() {
        return $('.workspace').append(Breeze.global_loader);
      },
      success: function(data) {
        var i, len, ref, results, token;
        $(".workspace").removeClass('rooms-empty').addClass('rooms');
        $(".workspace").html($(data).html());
        $(document).trigger("breeze:page", "rooms");
        $(document).trigger("breeze:page", "update-global-counters");
        if (Breeze.is_socket_connected() && Breeze.current_user.room_tokens) {
          ref = Breeze.current_user.room_tokens;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            token = ref[i];
            if (token) {
              results.push(Breeze.socket.emit("subscribe", token));
            } else {
              results.push(void 0);
            }
          }
          return results;
        }
      }
    });
  }
};

init_page = function() {
  var el, i, len, ref, token, topPos;
  if ($('.workspace.rooms').length > 0) {
    $(document).trigger("breeze:page", "reactions");
  }
  $('#main_header .dropdown').removeClass("open");
  // key.setScope("chat");
  $('.main .chat-messages-circle').addClass("hide");
  if ($('#editor-element').length > 0) {
    init_editor();
  }
  $(document).trigger("breeze:page", "update-global-counters");
  if (Breeze.is_socket_connected() && Breeze.current_user.room_tokens) {
    ref = Breeze.current_user.room_tokens;
    for (i = 0, len = ref.length; i < len; i++) {
      token = ref[i];
      if (token) {
        Breeze.socket.emit("subscribe", token);
      }
    }
  }
  if (Breeze.message_anchor) {
    el = $('.room-message-box[data-message-id="' + Breeze.message_anchor + '"]');
    if (el.length > 0) {
      if ($('#project-messages-modal.in').length > 0) {
        el[0].scrollIntoView({
          behavior: 'smooth'
        });
      } else {
        topPos = el.offset().top;
        $('.chat-right-content-body').animate({
          scrollTop: topPos
        }, 'fast');
      }
      el.effect("highlight", {}, 2000);
    }
  }
  $('.rooms').on("click", '.room-message-content-message a', function() {
    window.open($(this).attr("href"), '_blank');
    return false;
  });
  $('.rooms').on("click", ".breeze-uploaded-image", function(e) {
    return Breeze.init_image_magnify($(this));
  });
  $('.rooms').on("click", ".chat-right-content-footer", function() {
    $('.room-message-box .wysi-placeholder').remove();
    $('.room-message-content').show();
    return $('#editor-element-create').attr("id", "editor-element");
  });
  $('.rooms').on("click", ".chat-left-room", function(e, navigate_back) {
    var room_id, url;
    el = $(this);
    room_id = $(this).attr('data-room-id');
    if (developement()) {
      url = "https://app.breeze.test:3006/rooms/" + room_id + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n3.herokuapp.com/rooms/" + room_id + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        partial: true
      },
      beforeSend: function() {
        $('.chat-right-content').append(Breeze.global_loader);
        return $('.chat-right-content-room').css("opacity", "0");
      },
      success: function(data) {
        $(".chat-left-room").removeClass("active");
        el.addClass("active");
        $(".chat-right-content").html(data);
        init_editor();
        el.find('.room-messages-count').text("");
        url = "/messages/" + room_id;
        // if (!navigate_back) {
        //   window.history.pushState({
        //     "static": true,
        //     url: url,
        //     previous_url: window.history.state.url,
        //     previous_card_modal: window.history.state.card_modal,
        //     previous_todo_link: window.history.state.todo_link
        //   }, $("title").html(), url);
        //   return Breeze.navigate();
        // }
      }
    });
    return e.preventDefault();
  });
};

init_editor = function() {
	
	// console.log("sssssas")
	$("#project-messages-modal #editor-element").on('froalaEditor.focus', function (e, editor) {
		// console.log("ffff")
		Breeze.html_click = false
		$('#project-messages-modal').addClass("editing")
	});
	
	$("#project-messages-modal #editor-element").on('froalaEditor.blur', function (e, editor) {
		// console.log("bbbb")
		$('#project-messages-modal').removeClass("editing")
		
    setTimeout((function() {
			// console.log("ttttt")
			if (!Breeze.html_click) {
				// console.log("tstststst")
				$('#room-message-create').submit()
				Breeze.html_click = true
			}
			
    }), 150);
		
	});
	
	init_froala_setup()
	
  embed_images($('.room-message-content-message'));
  $('.atwho-container').remove();
  $('#editor-element').on('froalaEditor.initialized', function(e, editor) {
    var last_el;
    editor_setup(editor);
    $("#editor-element").froalaEditor('events.focus');
    last_el = $('.room-message-box:last')[0];
    if (last_el) {
      last_el.scrollIntoView();
    }
    Breeze.attach_before_ids = [];
    Breeze.attach_after_ids = [];
    return Breeze.attach_inserted_ids = [];
  });
  froala_setup.scrollableContainer = 'body';
  froala_setup.heightMin = 60;
  froala_setup.toolbarBottom = true;
  froala_setup.toolbarSticky = false;
  $('#editor-element').froalaEditor(froala_setup);
  $("#editor-element").froalaEditor("html.set", " ");
  // $("#editor-element").froalaEditor('events.focus');
	
  // $("#editor-element").froalaEditor('events.focus');

	
};

// ---
// generated by coffee-script 1.9.2