import {developement} from 'mobile/utils.js';

var init_page;

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "rooms") {
      return init_page();
    }
  });
  if ($('.workspace.rooms').length > 0) {
    return init_page();
  }
});

init_page = function() {
  $('.rooms').on("click", ".room-messages-load-older", function(e) {
    var container, el, href, url;
    href = $(this).attr("href");
    el = $(this);
    container = $(this).parent();
    if (developement()) {
      url = "https://app.breeze.test:3006";
    } else {
      url = "https://breeze-node-n3.herokuapp.com";
    }
    container.html(Breeze.global_loader);
    $.ajax({
      type: "GET",
      url: url + href,
      data: {
        partial: true
      },
      success: function(data) {
        var m;
        m = $(data).find('.chat-right-content-body');
        m.find('.room-messages-load-newer-container').remove();
        $(m.html()).insertAfter(container);
        return container.remove();
      }
    });
    return false;
  });
  $('.rooms').on("click", ".room-messages-load-newer", function(e) {
    var container, el, href, url;
    href = $(this).attr("href");
    el = $(this);
    container = $(this).parent();
    if (developement()) {
      url = "https://app.breeze.test:3006";
    } else {
      url = "https://breeze-node-n3.herokuapp.com";
    }
    container.html(Breeze.global_loader);
    $.ajax({
      type: "GET",
      url: url + href,
      data: {
        partial: true
      },
      success: function(data) {
        var m;
        m = $(data).find('.chat-right-content-body');
        m.find('.room-messages-load-older-container').remove();
        $(m.html()).insertAfter(container);
        return container.remove();
      }
    });
    return false;
  });
  return $('.rooms').on("click", ".room-messages-load-newest", function(e) {
    var room_id;
    room_id = $('.chat-right-content-room').attr('data-room-id');
    $('.chat-left [data-room-id="' + room_id + '"]').trigger("click");
    return false;
  });
};

// ---
// generated by coffee-script 1.9.2