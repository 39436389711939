import {developement} from 'mobile/utils.js';
import Cookies from 'js-cookie'
import PullToRefresh from 'mobile/utils/pullrefresh.js';
import {init_pull_to_refresh} from 'mobile/utils/pull_to_refresh.js';

Turbolinks.BrowserAdapter.prototype.showProgressBarAfterDelay = function() {
  return this.progressBarTimeout = setTimeout(this.showProgressBar, 0);
};

$.flashMessage = function(text, keep, wide) {
  if (wide == null) {
    wide = false;
  }
  if (wide) {
    $(".notification-bar-container .notification-bar-contents").addClass("wide-message");
  }
  $(".notification-bar-container .message").html(text);
  $(".notification-bar-container").slideDown();
  if (!keep) {
    return window.setTimeout((function() {
      return $(".notification-bar-container").slideUp();
    }), 2000);
  }
};


$(document).ready(function() {

init_pull_to_refresh()
	

	// document.documentElement.addEventListener('touchmove', function(e){
	//
	// 	if($(".card-inline-create textarea:focus").length >0 || $('#project-messages-modal.editing').length >0 ) {
	// 	  e.preventDefault();
	// 	}
	//
	//
	// }, {passive: false})




$(document).on("click", ".notification-bar-container", function() {
  $(this).slideUp();
});

$('#new_user').submit(function() {
  // var timezone, timezone_name;
  // timezone = jstz.determine();
  // timezone_name = timezone.name();
  // $('#user_timezone').val(timezone_name);
  $('#new_user .get-start').attr('disabled', 'true');
  $('#new_user .progress-button').show();
	
});

// $(document).on("click", ".pwa-dismiss", function(e) {
// 	console.log("ddd")
//
// 	return false;
//
// });

const pwaAuth = document.querySelector("pwa-auth");
pwaAuth && pwaAuth.addEventListener("signin-completed", ev => {
    const signIn = ev.detail;
    if (signIn.error) {
        console.error("Sign in failed", signIn.error);
    } else {
			console.log(ev)
			console.log(signIn)
			//         console.log(signIn.providerData.getAuthResponse(true)?.id_token)
			// 	console.log("Email: ", signIn.email);
			//         console.log("Name: ", signIn.name);
			//         console.log("Picture: ", signIn.imageUrl);
			//         console.log("Access token", signIn.accessToken);
			//         console.log("Access token expiration date", signIn.accessTokenExpiration);
			//         console.log("Provider (MS, Google, FB): ", signIn.provider);
			//         console.log("Raw data from provider: ", signIn.providerData);
				
				
				if (signIn.provider == "Apple") {
					$('.global-loader').addClass("show")
					window.location.replace("/users/auth/apple/callback?code=" + signIn.accessToken)
				}
				else if (signIn.provider == "Google") {
					$('.global-loader').addClass("show")
					
					var id_token = signIn.providerData.getAuthResponse(true)?.id_token
					$('.google2-form input[name="email"]').val(signIn.email)
					$('.google2-form input[name="name"]').val(signIn.name)
					$('.google2-form input[name="id_token"]').val(id_token)
					
					$('.google2-form').submit()
				}
    }
});

document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    // Handle successful response.
    console.log(event);
					$('.global-loader').addClass("show")
					window.location = "https://m.breeze.pm/users/auth/apple/callback?code=" + event.detail.authorization.code
		
});

// Listen for authorization failures.
document.addEventListener('AppleIDSignInOnFailure', (event) => {
     // Handle error.
	console.log("error11")
     console.log(event.detail.error);
});

// $(document).on("click", ".google-signin-button", function() {
// 	pwaAuth.signIn("Google");
// 	return false;
// });
$(document).on("click", ".apple-signin-button", function() {
	// async (dispatch) => {
		// console.log("clclclcllc")
		// console.log(AppleID)
	try {
	     const data =  AppleID.auth.signIn()
	     // Handle successful response.
		// console.log("eeee")
			// console.log(data)
	} catch ( error ) {
		// console.log("errrr")
		console.log(error)
	     // Handle error.
	}
// }
	// pwaAuth.signIn("Apple")
	return false;
	
});

$(document).on("click", "html", function(e) {
	// console.log("dcdcdcdcdc")
	if ($(e.target).closest(".wysi-placeholder").length <= 0) {
	// console.log("dcdcdcdcdc00000")
		
		Breeze.html_click = true
	}

  if ($('.calendar-fluid').length > 0) {
    if (!($(e.target).closest(".popover").length > 0 && !$(e.target).hasClass("board-link"))) {
      $('.calendar-list li a[data-pop="true"]').popover("hide");
      $('.calendar-list li a[data-pop="true"]').attr("data-pop", "");
      $('.calendar-settings[data-pop="true"]').popover("hide");
      $('.calendar-settings[data-pop="true"]').attr("data-pop", "");
      $(".popover").remove();
      $('.todo-items [data-action="todo-assign"]').removeData('bs.popover');
			$('.todo-items [data-action="todo-assign"]').removeData('bs.popover')
			$('#card_modal .card-side .element-custom-fields').removeData('bs.popover')
			$('.card-side .element-more').removeData('bs.popover')
			$('#card_modal .card-swimlane-list a').removeData('bs.popover')
			$('#card_modal .custom-field-value-display').removeData('bs.popover')
    }
  } else if ($('.people-container').length > 0 || $('.container.tags').length > 0) {
    if (!($(e.target).closest(".popover").length > 0 && !$(e.target).hasClass("board-link"))) {
      $(".popover").remove();
    }
  } else if ($('#card_modal.show').length > 0 || $('.dashboard').length > 0) {
    if (!($(e.target).closest(".popover").length > 0 && !$(e.target).hasClass("board-link"))) {
      $(".popover").remove();
			$('#card_modal .todos .fa-ellipsis-v').css('visibility','')
			
      // $(".modal .todos .todo-items").sortable({
      //   disabled: false
      // });
      // $(".modal .todos .list").sortable({
      //   disabled: false
      // });
      $('.todo-items [data-action="todo-assign"]').removeData('bs.popover');
      $('.card-side .element-more').removeData('bs.popover');
      $('#card_modal .custom-field-value-display').removeData('bs.popover');
      $('#card_modal .card-side .element-custom-fields').removeData('bs.popover');
      $('#card_modal .hover-inner-wrapper').removeClass("hover-in");
      $('.dashboard .right-menu .hover-inner-wrapper').removeClass("hover-in");
      Breeze.card_menu = false;
      if (!$(e.target).parent().hasClass("person-email")) {
        $(".tooltip").remove();
      }
    }
		
	 else if ($(e.target).closest(".popover").length > 0) {
		 return false
	 }
  } else if ($(e.target).closest(".popover").length <= 0) {
    $(".popover").remove();
  }
  if ($('.card-inline-create1').length > 0) {
    if ($('.card-inline-create textarea').val().trim().length > 0) {
      if ($('#mass-create').length <= 0) {
        return $('.card-inline-create .save').trigger("click");
      }
    } else {
      return $('.card-inline-create').remove();
    }
  }
});

var fetch_page, fetch_projects_data, url;
if ($(".workspace").length > 0 && $('.web').length <= 0 && $('.public-project').length <= 0) {
  Breeze.projects_stages = [];
  Breeze.project_tags = [];
  Breeze.project_tags_hidden = [];
	
  Breeze.all_team_users = [];
  Breeze.team_users = [];
  if (developement()) {
    url = "https://app.breeze.test:3006/projects/global_data_user.json?auth_token=" + Breeze.current_user.token;
  } else {
    url = "https://breeze-node-n.herokuapp.com/projects/global_data_user.json?auth_token=" + Breeze.current_user.token;
  }
  $.ajax({
    type: "GET",
    url: url,
    success: function(data) {
      if (data.project_tags) {
        Breeze.project_tags = data.project_tags;
      }
      if (data.project_tags_hidden) {
        Breeze.project_tags_hidden = data.project_tags_hidden;
      }
      if (data.all_team_users) {
        Breeze.all_team_users = data.all_team_users;
      }
      if (data.team_users) {
        Breeze.team_users = data.team_users;
      }
						
      // return Breeze.init_task_parser();
    }
  });
  fetch_page = 1;
  fetch_projects_data = function(fetch_page) {
    if (developement()) {
      url = "https://app.breeze.test:3006/projects/global_data_projects.json?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n.herokuapp.com/projects/global_data_projects.json?auth_token=" + Breeze.current_user.token;
    }
    return $.ajax({
      type: "GET",
      url: url,
      data: {
        page: fetch_page
      },
      success: function(data) {
        var ref, ref1;
        if (data.projects_stages) {
          (ref = Breeze.projects_stages).push.apply(ref, data.projects_stages);
        }
        // Breeze.init_task_parser();
        fetch_page += 1;
        if (fetch_page < 7 && (data != null ? (ref1 = data.projects_stages) != null ? ref1.length : void 0 : void 0) > 0 && data.next_page) {
          return fetch_projects_data(fetch_page);
        }
      }
    });
  };
  fetch_projects_data(fetch_page);
}

});

let deferredPrompt;


window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    // console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      // console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      // console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      // console.log('Service worker active.');
    }
  }).catch(registrationError => {
    // console.log('Service worker registration failed: ', registrationError);
  });
	
	let installable = true;
	if (!('serviceWorker' in navigator)){
	  installable = false;
	}
	
	// Cookies.remove('breeze_pwa_dismiss')
	
	let breeze_pwa_dismiss = Cookies.get('breeze_pwa_dismiss')
	
	// if (installable && !breeze_pwa_dismiss) {
	//
	//
	//
	// 	const isInStandaloneMode = () => (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches);
	//
	// 	const isIos = () => {
	// 	  const userAgent = window.navigator.userAgent.toLowerCase();
	//
	// 		if (['iPad Simulator', 'iPhone Simulator','iPod Simulator', 'iPad','iPhone','iPod'].includes(navigator.platform)) {
	// 		  return true;
	// 	  }
	// 	  else if (navigator.userAgent.includes("Mac") && "ontouchend" in document){
	// 	     return true;
	// 	   }
	// 	   else {
	// 	     return false;
	// 	   }
	// 	}
	//
	//
	// 	window.addEventListener('beforeinstallprompt', function (e) {
	// 	  // Prevent Chrome 67 and earlier from automatically showing the prompt
	// 	  e.preventDefault();
	// 	  // Stash the event so it can be triggered later.
	// 	  deferredPrompt = e;
	//
	// 		if (!isInStandaloneMode() && !isIos()) {
	// 		  showAddToHomeScreen();
	// 		}
	// 	});
	//
	//
	// 	// Detects if device is on iOS
	//
	// 	// Detects if device is in standalone mode
	//
	// 	// Checks if should display install popup notification:
	// 	if (isIos() && !isInStandaloneMode()) {
	// 	  // this.setState({ showInstallMessage: true });
	// 	  showIosInstall();
	// 	}
	// }
	
	
 
	
});


function addToHomeScreen() {
	// console.log("bbbbbbb")
  let a2hsBtn = document.querySelector(".ad2hs-prompt");  // hide our user interface that shows our A2HS button
  a2hsBtn.style.display = 'none';  // Show the prompt
  deferredPrompt.prompt();  // Wait for the user to respond to the prompt
  deferredPrompt.userChoice
    .then(function(choiceResult){

  if (choiceResult.outcome === 'accepted') {
    // console.log('User accepted the A2HS prompt');
  } else {
    // console.log('User dismissed the A2HS prompt');
  }

  deferredPrompt = null;

});}
function dismissPwa(ev) {
  let a2hsBtn = document.querySelector(".ad2hs-prompt");
  a2hsBtn.style.display = 'none';  // Show the prompt
	
  let iosBtn = document.querySelector(".ios-prompt");
  iosBtn.style.display = 'none';  // Show the prompt
	
	Cookies.set('breeze_pwa_dismiss', 'value', { expires: 7 })
	
	ev.stopPropagation();
	ev.preventDefault();
	return false
}
function showAddToHomeScreen() {

  let a2hsBtn = document.querySelector(".ad2hs-prompt");
  let pwaDismiss = document.querySelector(".pwa-dismiss");
	
  a2hsBtn.style.display = "block";
  a2hsBtn.addEventListener("click", addToHomeScreen);
  pwaDismiss.addEventListener("click", dismissPwa);

}



function showIosInstall() {
  let iosPrompt = document.querySelector(".ios-prompt");
  iosPrompt.style.display = "block";
  let pwaDismiss = document.querySelector(".pwa-dismiss-ios");
	
  iosPrompt.addEventListener("click", () => {
		let iosPromptSteps = document.querySelector(".ios-prompt-steps");
		
    iosPromptSteps.style.display = "block";
  });
	
  pwaDismiss.addEventListener("click", dismissPwa);
	
}


Date.prototype.getWeekYear = function ()   
{  
    // Create a new date object for the thursday of this week  
    var target  = new Date(this.valueOf());  
    target.setDate(target.getDate() - ((this.getDay() + 6) % 7) + 3);  
      
    return target.getFullYear();  
} 

Breeze.CardDate = function() {
        function t(e, t) {
            this.calendarDate = e, this.CardTime = t, this.year = this.calendarDate.year, this.month = this.calendarDate.month, this.day = this.calendarDate.day, this.hour = this.CardTime.hour, this.minute = this.CardTime.minute, this.second = this.CardTime.second
        }
        var e;
        return t.dateform = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|[-+]?[\d:]+)$/, t.parse = function(e, t) {
            var n, r;
            return n = Breeze.CalendarDate.parse(e), r = Breeze.CardTime.parse(t), new this (n, r)
        }, e = function(e) {
            return JSON.parse(JSON.stringify(e))
        }, t
    }()
		
		Breeze.CardTime = function() {
		        function t(e, t, n) {
		            var r, i, s;
		            this.hour = e != null ? e : 0, this.minute = t != null ? t : 0, this.second = n != null ? n : 0;
		            if (!(0 <= (r = this.hour) && r <= 23 && 0 <= (i = this.minute) && i <= 59 && 0 <= (s = this.second) && s <= 59))
		                throw Error("(" + this.hour + ", " + this.minute + ", " + this.second + ")");
		            this.format = this.hour < 12 ? "am" : "pm", this.hour === 0 ? this.hour12 = 12 : this.hour > 12 ? this.hour12 = this.hour-12 : this.hour12 = this.hour
		        }
		        var e;
		        return t.pattern = /^\s*(\d{1,2})(?:[.:]?([0-5]\d?)?)?(?:[.:]?([0-5]\d?)?)?(?:\s*([ap])(?:\.?m\.?)?|\s*[h]?)?\s*$/i, t.parse = function(e) {
		            var t, n, r, i, s, o, u, a, f;
		            a = "" + e;
		            if (e instanceof Date)
		                return r = e.getHours(), s = e.getMinutes(), u = e.getSeconds(), new this (r, s, u);
		            if (a.match(Breeze.CardDate.dateform))
		                return this.parse(new Date(Date.parse(a)));
		            if (i = a.match(this.pattern))
		                return f = i[0], r = i[1], s = i[2], u = i[3], n = i[4], r = parseInt(r, 10), s = parseInt(s != null ? s : "0", 10), u = parseInt(u != null ? u : "0", 10), t = n != null ? n.match(/a/i) : void 0, o = n != null ? n.match(/p/i) : void 0, 1 <= r && r <= 11 && o && (r += 12), r === 12 && t && (r = 0), new this (r, s, u);
		            throw Error(" (" + e + ")")
		        }
						, t.prototype.toString = function(t) {
						            var n;
						            return t == null && (t = {}), n = [e(this.minute)], t.short && this.minute === 0 && (n.pop(), this.minute === 0 && n.pop()), n.length && (n = ":" + n.join(":")), t[12] ? "" + this.hour12 + n + this.format : "" + [e(this.hour)] + n
						        }, t.prototype.toFriendlyString = function() {
						            return this.toString({
						                12: !Breeze.clock24,
						                "short": !Breeze.clock24
						            })
						        }
						, e = function(e) {
		            return e < 10 ? "0" + e : "" + e
		        }, t
		    }()		




